import React from 'react'
import {useSelector} from 'react-redux';
import { formatNumber } from '../../../helpers/helpers';
function TextButtonCell(props) {
    const {text,buttonText, month, state, textRed} = props;
    const input_value = useSelector(state => state.finance[month]);
  return (
    <div className='container_grid_three'>
    <div className='text_cell'>{buttonText}</div>
    <div></div>
    <div className={`input_finance text_center ${textRed? "text_red": ""}`}>{input_value? ((input_value[state] > 0) ? formatNumber(text): formatNumber(text)) : ""}</div>
</div>
  )
}

export default TextButtonCell

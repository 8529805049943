import React from 'react'
import "./Pointer.css"
function Pointer(props) {
    const {info, state, point1, point2 , updateState, list} = props ;
    const changeConection = ()=>{
        updateState([...state.slice(0,point1), {...state[point1],
     [list]:[...state[point1][list].slice(0, point2),{...state[point1][list][point2], connection: !(info.connection)},...state[point1][list].slice(1 + point2)]}
     ,...state.slice(1 +point1) ]);
    };
  return (
    <div className='pointer'>
        <div className={`${info.connection? "dot_blue": "dot"}`} onClick={changeConection}></div>
    </div>
  )
}

export default Pointer;
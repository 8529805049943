import React, { Fragment } from 'react';
import "./SalesModal.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from "react-redux";

function SalesModal({ icon, title, bodyText }) {
  const dispatch = useDispatch();

  function salesButtonFunc() {
    dispatch({ type: "checkbox1", payload: false });
    return;
  }

  return (
    <Fragment>
      <div className="sales_modal">
        <div className="sales_modal_content">
          <div className='sales_popup'>
            <button className='sales_button_x' onClick={() => salesButtonFunc()}>X</button>
            <FontAwesomeIcon icon={icon} className='icon_sales' />

            <h4 className='sales_title'>{title}</h4>
            <div className='sales_text_box'>
              <h4 className='sales_text_body body-text-ul'>{bodyText }</h4>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SalesModal;

import React, { Fragment, useState } from "react";
import SalesCard from "../../components/SalesCard/SalesCard";
import "./Sales.css";
import SalesModal from "../../components/Modals/SalesModal/SalesModal";
import { useSelector } from "react-redux";
import {
  faAward,
  faGears,
  faHeadset,
  faMedal,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";
import { useTranslation } from 'react-i18next';



function Sales() {
  const { t} = useTranslation('Sales');
  const salesData = [
    {
      icon: faAward,
      title: t("Choosing high product quality"),
      bodyText: (
        <ul>
          <li>{t("The longest warranty")}</li>
          <li>{t("Uncompromising materials")}</li>
          <li>{t("Design and style")}</li>
          <li>{t("Environmental standards")}</li>
          <li>{t("Comparison to other products")}</li>
          <li>{t("Reviews and recommendations")}</li>
        </ul>
      ),
    },
    {
      icon: faTag,
      title: t("The most competitive price in the industry"),
      bodyText: (
        <ul>
          <li>{t("The highest cost-benefit ratio")}</li>
          <li>{t("Comparison to competitors")}</li>
        </ul>
      ),
    },
    {
      icon: faHeadset,
      title: t("The best service experience in the industry"),
      bodyText: (
        <ul>
          <li>{t("Excellent customer service")}</li>
          <li>{t("Customized user experience")}</li>
          <li>{t("Attention to details that are important to the customer")}</li>
        </ul>
      ),
    },
    {
      icon: faGears,
      title: t("Project management"),
      bodyText: (
        <ul>
          <li>{t("Adherence to deadlines")}</li>
          <li>{t("Use of project management software")}</li>
          <li>{t("Advance planning and client approval according to requirements")}</li>
          <li>{t("Monitoring planning versus execution and minimizing gaps")}</li>
        </ul>
      ),
    },
    {
      icon: faMedal,
      title: t("Leadership in innovation and technology"),
      bodyText: (
        <ul>
          <li>{t("Use of advanced engineering software")}</li>
          <li>{t("Use of advanced manufacturing processes")}</li>
          <li>{t("Use of advanced machinery")}</li>
          <li>{t("Implementation of advanced product quality controls")}</li>
          <li>{t("Utilization of leading talent")}</li>
        </ul>
      ),
    },
  ];
  const checkboxState = useSelector((state) => state.sales.checkbox1);
  const [selectedCard, setSelectedCard] = useState(null);




  function handleCardClick(card) {
    console.log("Card clicked:", card);
    setSelectedCard(card);
  }

  return (
    <Fragment  >

      

  <div className="video-container_p">
        <VideoNewModal videoUrl={t("video_url1")} />
      </div>



      {checkboxState && selectedCard && (
        <SalesModal
          icon={selectedCard.icon}
          title={selectedCard.title}
          bodyText={selectedCard.bodyText}
        />
      )}
      <div className="grid_container_sales">
        {salesData.map((card, index) => (
          <SalesCard
            key={index}
            icon={card.icon}
            text={card.title}
            onClick={() => handleCardClick(card)}
          />
        ))}
      </div>
    </Fragment>
  );
}

export default Sales;

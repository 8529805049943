import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import "./Language.css";

const LanguageSelector = () => {
  const { i18n } = useTranslation(["Finance"]);
  const handleLanguageChange = (e) => {
    e.preventDefault();
    i18n.changeLanguage(e.target.value);
  };
  if (i18n.language === "he") {
    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  } else document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
  return (
    <div className="select_language_container">
      <FontAwesomeIcon icon={faGlobe} />
      <select
        name="languageSelect"
        onChange={(e) => handleLanguageChange(e)}
        value={i18n.language}
      >
        <option value="en">EN</option>
        <option value="he">HE</option>
      </select>
    </div>
  );
};

export default LanguageSelector;

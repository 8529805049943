import * as XLSX from 'xlsx'


export function handleExport(data){

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Income");
    // יצירת הקובץ
    XLSX.writeFile(workbook, 'output.xlsx');

}
// import logo from '../../Pictures/logo_transparent.png';
import "./Navbar.css";
import LanguageSelect from "../Selectors/LanguageSelect/LanguageSelect";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import CurrencySelctor from "../Selectors/CurrencySelctor/CurrencySelctor";

const Navbar = () => {
  const dispatch = useDispatch();
  const ai = useSelector((state) => state.income.ai);

  return (
    <nav className="top_navbar">
      <LanguageSelect />
      <CurrencySelctor />
      <div
        className="video-nav-container"
        onClick={() => {
          dispatch({ type: "Video_open", payload: true });
        }}
      >
        <FontAwesomeIcon icon={faVideo} />
      </div>
      <button
        className="Ai"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          dispatch({ type: "ai", payload: !ai });
        }}
      >
        talk to AI{" "}
      </button>
    </nav>
  );
};

export default Navbar;

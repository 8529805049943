import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SideMenu.css";
import {
  faListCheck,
  faTag,
  faMagnifyingGlassChart,
  faBrain,
  faHouse,
  faDoorOpen,
  faPeopleGroup,
  faUserPlus,
  faUserGear,
  faFaceSmile,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

// import logo from '../../Pictures/logo_transparent.png';

function SideMenu() {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleMouseLeave = () => {
    setOpenIndex(null);
  };

  const isOpen = (index) => {
    return openIndex === index;
  };

  const { t, i18n } = useTranslation("App");

  return (
    <div
      className={`menu_home ${i18n.language === "he" ? "right" : "left"}`}
      onMouseLeave={handleMouseLeave}
    >
      <div className="logo">
        <div className="transparent_logo"></div>
      </div>
      <div className="link">
        <FontAwesomeIcon icon={faHouse} color="white" />
        <Link to={"/home"} className="router_link_home">
          {t("home")}
        </Link>
      </div>
      {[
        {
          icon: faListCheck,
          title: `${t("finance_management")}`,
          links: [
            { to: "/first", text: `${t("create_financial_report")}` },
            { to: "/second", text: `${t("budgets_control")}` },
            { to: "/third", text: `${t("cash_flow_management")}` },
          ],
        },
        {
          icon: faTag,
          title: `${t("sales_and_marketing")}`,
          links: [
            { to: "/sales", text: `${t("marketing_startegy")}` },
            { to: "/sales-customer", text: `${t("customer_journey")}` },
            { to: "/advertising-channels", text: `${t("advertising_channels")}` },
            { to: "/sales-training", text: `${t("sales_training")}` },
            { to: "/sales-managment", text: `${t("sales_managment")}` },
            { to: "/sales-effectiveness", text: `${t("marketing_effectiveness_control")}` },
          ],
        },

        {
          icon: faMagnifyingGlassChart,
          title: `${t("management_and_operations")}`,
          links: [
            {to: "/creating-workflow",text: `${t("creating_workflows")}`},
            {to: "/define-rules",text: `${t("create_work_procedure")}`},
            {to: "/confined-work-plan",text: `${t("confined_work_plan")}`},
            {to: "/customer-experience",text: `${t("control_customer_experience")}`},
            {text: `${t("learning_lessons")}`},
            {text: `${t("scenario_&_risk_managment")}`},
            {text: `${t("decision_making")}`},
            {text: `${t("planing_versus_execution")}`},
          ],
        },
        { icon: faBrain, title: `${t("help_in_making_decisions")}` },
        { icon: faPeopleGroup, title: `${t("team_quality")}` },
        { icon: faUserPlus, title: `${t("manager_expertise")}` },
        { icon: faUserGear, title: `${t("managerial_skills")}` },
        { icon: faFaceSmile, title: `${t("personal_characteristics")}` },
      ].map((item, index) => (
        <details
          key={index}
          className={`details_select ${isOpen(index) ? "open" : ""}`}
          open={isOpen(index)}
        >
          <summary
            className={i18n.language === "he" ? "summary-he" : "summary-en"}
            onClick={(e) => {
              e.preventDefault();
              handleToggle(index);
            }}
          >
            <FontAwesomeIcon icon={item.icon} />
            <span className="router_link_home">{item.title}</span>
          </summary>
          {item.links &&
            item.links.map((link, linkIndex) => (
              <div className="link" key={linkIndex}>
                {/* <FontAwesomeIcon icon={link.icon} /> */}
                <Link to={link.to} className={`router_link_home `}>
                  {link.text}
                </Link>
              </div>
            ))}
        </details>
      ))}
      <div className="link">
        <FontAwesomeIcon icon={faDoorOpen} color="white" />
        <Link to={"/login"} className="router_link_home">
          {t("exit")}
        </Link>
      </div>
    </div>
  );
}

export default SideMenu;

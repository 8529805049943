

import React, { Fragment } from "react";
import { useSelector, } from "react-redux";
import "./MonthTableRow.css";
import { months} from '../../../helpers/helpers';

import { formatNumber } from '../../../helpers/helpers';
function MonthTableBP({id, text}) {


    const thisMonth = useSelector(state => state.finance);
    const totalSpend = months.reduce((base,mon)=>{
        return base += +thisMonth[mon].salary_and_related_expenses + +thisMonth[mon].financial_expenses  + +thisMonth[mon].operational_and_managment
    },0);
    const totalExpensesY = months.reduce((base,mon)=>{
      return base += +thisMonth[mon].inventory_and_supplier_expenses
  },0);
    const totalMonthIncome = useSelector(state => state.finance.Total.total);
    return (
        <Fragment>
              <input className="input-table-f"
              readOnly
                value={text}/>
             {months.map(( mon ,i ) => (
               <input key={i} className="input-table-f"
               defaultValue={formatNumber(thisMonth[mon][id])}
               disabled
               />
              ))}

              <input className="input-table-f"
              defaultValue={(totalMonthIncome === 0)? 0: formatNumber((totalSpend/ (1 - (totalExpensesY / totalMonthIncome)))/12) }
              disabled
              />
              <input className="input-table-f"
              defaultValue={(totalMonthIncome === 0)? 0: formatNumber((totalSpend/ (1 - (totalExpensesY / totalMonthIncome)))) }
              disabled
              />
              </Fragment>
      );
    }



export default MonthTableBP;
// const defaultMonthData = {
//   date: "",
//   customerName: "",
//   contactName: "",
//   phoneNumber: "",
//   status1: "",
//   status2: "",
//   status3: "",
//   totalAmount: "",
//   preTaxAmount: "",
//   monthlyEntry: "",
//   status: "",
// };

// const defaultSalesState = {
//   checkbox1: false,

//   month: "select month",
//   January: [defaultMonthData],
//   February: [defaultMonthData],
//   March: [defaultMonthData],
//   April: [defaultMonthData],
//   May: [defaultMonthData],
//   June: [defaultMonthData],
//   July: [defaultMonthData],
//   August: [defaultMonthData],
//   September: [defaultMonthData],
//   October: [defaultMonthData],
//   November: [defaultMonthData],
//   December: [defaultMonthData],
// };

// export const salesReducer = (state = defaultSalesState, action) => {
//   switch (action.type) {
//     case "checkbox1":
//       return { ...state, checkbox1: action.payload };
//     case "month":
//       return { ...state, month: action.payload };
//     case "January_sales_data":
//     case "February_sales_data":
//     case "March_sales_data":
//     case "April_sales_data":
//     case "May_sales_data":
//     case "June_sales_data":
//     case "July_sales_data":
//     case "August_sales_data":
//     case "September_sales_data":
//     case "October_sales_data":
//     case "November_sales_data":
//     case "December_sales_data":
//       return { ...state, [action.type.split("_")[0]]: action.payload };

//     default:
//       return state;
//   }
// };

const defaultMonthData = {
  rows: [],
  headers: [
    "date",
    "customerName",
    "contactName",
    "phoneNumber",
    "status1",
    "status2",
    "status3",
    "totalAmount",
    "preTaxAmount",
    "dealStatus",
  ],
};

const defaultSalesState = {
  checkbox1: false,
  month: "select month",
  January: defaultMonthData,
  February: defaultMonthData,
  March: defaultMonthData,
  April: defaultMonthData,
  May: defaultMonthData,
  June: defaultMonthData,
  July: defaultMonthData,
  August: defaultMonthData,
  September: defaultMonthData,
  October: defaultMonthData,
  November: defaultMonthData,
  December: defaultMonthData,
};

export const salesReducer = (state = defaultSalesState, action) => {
  switch (action.type) {
    case "checkbox1":
      return { ...state, checkbox1: action.payload };
    case "month":
      return { ...state, month: action.payload };
    case "January_sales_data":
    case "February_sales_data":
    case "March_sales_data":
    case "April_sales_data":
    case "May_sales_data":
    case "June_sales_data":
    case "July_sales_data":
    case "August_sales_data":
    case "September_sales_data":
    case "October_sales_data":
    case "November_sales_data":
    case "December_sales_data":
      return {
        ...state,
        [action.type.split("_")[0]]: action.payload,
      };
    default:
      return state;
  }
};

import React, { Fragment } from "react";
import "./MonthSelectIncome.css";
// import { months } from '../../helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
function MonthSelectIncome(props) {
  const { text, setIndex, resetForm, months } = props;
  const dispatch = useDispatch();
  const divs = document.querySelectorAll(".month");
  const theMonth = useSelector((state) => state.income);
  return (
    <Fragment>
      <div className="container_grid_13 text_center ">
        {months.map((mon, i) => (
          <Fragment key={i}>
            <p
              className="month"
              id={mon}
              onClick={(e) => {
                if (!(e.target.id === "Total" || e.target.id === "Average")) {
                  document.getElementById(resetForm).reset();
                  divs.forEach((div) => {
                    div.classList.remove("picked");
                  });
                  dispatch({ type: "month", payload: mon });
                  setIndex(i);
                  dispatch({
                    type: "month_action",
                    payload: theMonth[mon].Month_action,
                  });
                  dispatch({ type: "total_income", payload: 0 });
                  dispatch({ type: "total_expense", payload: 0 });
                  dispatch({ type: "current_account_status_base", payload: 0 });
                  dispatch({ type: "account_frame", payload: 0 });

                  e.currentTarget.classList.add("picked");
                }
              }}
            >
              {text[i]}
            </p>
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
}

export default MonthSelectIncome;

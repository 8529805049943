import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './LoginSignUp.css';
import { useTranslation } from "react-i18next";

function LoginSignUp() {
  const [activeTab, setActiveTab] = useState(0);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [businessField, setBusinessField] = useState('');
  const [establishmentDate, setEstablishmentDate] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [error, setError] = useState('');
  const [isSecondStep, setIsSecondStep] = useState(false);
  const navigate = useNavigate();

  const { t} = useTranslation("Login");

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    setError('');
    navigate('/home');
  };

  const handleSignUpSubmit = (e) => {
    e.preventDefault();
    setError('');
    if (!isSecondStep) {
      setIsSecondStep(true);
    } else {
      navigate('/home');
    }
  };



  return (
    <div className="entire">
      <div className="text-and-login">
        <div className="logo-section">
          <img src="/Pictures/logo_transparent.png" alt="Six Keys Logo" className="login-logo" />
          <p className="tagline">{t("Leading Businesses to")}<strong>{t("Magical")}</strong> {t("Growth")}</p>
        </div>

        <div className="login-container">

          <div className="content-box">
            <div className="tabs">
              <button
                className={`tab ${activeTab === 0 ? 'active-tab' : ''}`}
                onClick={() => handleTabChange(0)}
              >
                  {t("Sign Up")}

              </button>
              <button
                className={`tab ${activeTab === 1 ? 'active-tab' : ''}`}
                onClick={() => handleTabChange(1)}
              >
                {t("Login")}

              </button>
            </div>

            {activeTab === 0 && (
              <form onSubmit={handleSignUpSubmit} className="form-box">
                {!isSecondStep ? (
                  <>
                    <input
                      type="text"
                      required
                      placeholder={t("Full Name")}
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      className="input-field"
                    />
                    <input
                      type="email"
                      required
                      placeholder={t("Email")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="input-field"
                    />
                    <input
                      type="password"
                      required
                      placeholder={t("Password")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="input-field"
                    />
                    {error && <p className="error-text">{error}</p>}
                    <button type="submit" className="submit-button">{t("Continue")}</button>
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      required
                      placeholder={t("Business Field")}
                      value={businessField}
                      onChange={(e) => setBusinessField(e.target.value)}
                      className="input-field"
                    />
                    <input
                      type="date"
                      required
                      placeholder={t("Business Establishment Date")}
                      value={establishmentDate}
                      onChange={(e) => setEstablishmentDate(e.target.value)}
                      className="input-field"
                    />
                    <select
                      required
                      value={companySize}
                      onChange={(e) => setCompanySize(e.target.value)}
                      className="input-field"
                    >
                      <option value="">{t("How large is your company?")}</option>
                      <option value="Just me">{t("Just me")}</option>
                      <option value="1-5">1-5</option>
                      <option value="5-25">5-25</option>
                      <option value="25-100">25-100</option>
                      <option value="250-1000">250-1000</option>
                      <option value="1000+">1000+</option>
                      <option value="Prefer not to share">{t("Prefer not to share")}</option>
                    </select>
                    <p className="terms-text">
                     {t(" By proceeding, you agree to the")} <Link to="/terms">{t("Terms and Conditions")}</Link> {t("and")} <Link to="/privacy">{t("Privacy Policy")}</Link>.
                    </p>
                    <div className="button-group">
                      <button type="button" className="back-button" onClick={() => setIsSecondStep(false)}>{t("Back")}</button>
                      <button type="submit" className="submit-button">{t("Sign Up")}</button>
                    </div>
                  </>
                )}
              </form>
            )}

            {activeTab === 1 && (
              <form onSubmit={handleLoginSubmit} className="form-box">
                <input
                  type="text"
                  required
                  placeholder={t("Username")}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="input-field"
                />
                <input
                  type="password"
                  required
                  placeholder={t("Password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input-field"
                />
                {error && <p className="error-text">{error}</p>}
                <button type="submit" className="submit-button">{t("Login")}</button>
              </form>
            )}
          </div>

          <div className="side-title">
            <h4>{t("We help businesses grow faster and better")}</h4>
          </div>

        </div>


      </div>
    </div>
  );
}

export default LoginSignUp;

import axios from "axios";

 export const BudgetPostReq = async (data) => {
  try {
    console.log(data)
    const response = await axios({
      method: "post",
      url: "http://localhost:5000/api/budget",
      data:data
    });
    console.log(response)
  } catch (error) {
    console.log(error)
  }
};

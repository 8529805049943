// import React, { useState, Fragment, useEffect } from "react";
// import "./SalesManagment.css";
// import { months } from "../../helpers/helpers.js";
// import SalesTable from "../../components/Tables/SalesTable/SalesTable";
// import { useSelector, useDispatch } from "react-redux";
// import MonthSelectSales from "../../components/Selectors/MonthSelectSales/MonthSelectSales";
// import { useTranslation } from "react-i18next";

// export default function SalesManagment() {
//   const dispatch = useDispatch();
//   const { t } = useTranslation("SalesManagment");
//   const [index, setIndex] = useState(0);

//   const currency = useSelector((state) => state.income.currency);
//   const month = useSelector((state) => state.sales.month);
//   const currentMonthData = useSelector((state) => state.sales[month]);
//   const month_list = t("month_text").split(",");

//   const [rows, setRows] = useState(currentMonthData || []);

//   useEffect(() => {
//     setRows(currentMonthData || []);
//   }, [currentMonthData]);

//   const handleInputChange = (index, field, value) => {
//     const updatedRows = rows.map((row, i) =>
//       i === index ? { ...row, [field]: value } : row
//     );
//     setRows(updatedRows);

//     // עדכון הנתונים ב-Redux
//     dispatch({
//       type: `${month}_sales_data`,
//       payload: updatedRows,
//     });
//   };

//   const addRow = () => {
//     setRows([
//       ...rows,
//       {
//         date: "",
//         customerName: "",
//         contactName: "",
//         phoneNumber: "",
//         status1: "",
//         status2: "",
//         status3: "",
//         totalAmount: "",
//         preTaxAmount: "",
//         monthlyEntry: "",
//         status: "",
//       },
//     ]);
//   };

//   const saveData = (e) => {
//     e.preventDefault();
//     dispatch({
//       type: `${month}_sales_data`,
//       payload: rows,
//     });
//     alert("Data saved successfully!");

//     // Reset form after saving data
//     document.getElementById("salesForm").reset();
//     setRows([]);
//   };

//   return (
//     <Fragment>
//       <div className="container">
//         <MonthSelectSales
//           text={month_list}
//           setIndex={setIndex}
//           resetForm={"salesForm"}
//           months={months}
//         />
//       </div>
//       <form id="salesForm" onSubmit={saveData}>
//         <div className="sales-management-container">
//           <SalesTable
//             rows={rows}
//             handleInputChange={handleInputChange}
//             currency={currency}
//           />
//           <button type="button" className="button-sales" onClick={addRow}>
//             +
//           </button>
//           <button type="submit" className="button-save-all">
//             Save
//           </button>
//         </div>
//       </form>
//     </Fragment>
//   );
// }
import React, { useState, Fragment, useEffect } from "react";
import "./SalesManagment.css";
import { months } from "../../helpers/helpers.js";
import SalesTable from "../../components/Tables/SalesTable/SalesTable";
import { useSelector, useDispatch } from "react-redux";
import MonthSelectSales from "../../components/Selectors/MonthSelectSales/MonthSelectSales";
import { useTranslation } from "react-i18next";
import ProcessesSales from "../../components/SalesTrainingArticals/ProcessesSales.js";

export default function SalesManagment() {
  const dispatch = useDispatch();
  const { t } = useTranslation("SalesManagment");
  const [index, setIndex] = useState(0);


  const currency = useSelector((state) => state.income.currency);
  const month = useSelector((state) => state.sales.month);
  const currentMonthData = useSelector((state) => state.sales[month]);
  const month_list = t("month_text").split(",");

  const [rows, setRows] = useState(currentMonthData?.rows || []);
  const [headers, setHeaders] = useState(
    currentMonthData?.headers || [
      "date",
      "customerName",
      "contactName",
      "phoneNumber",
      "status1",
      "status2",
      "status3",
      "totalAmount",
      "preTaxAmount",
      "dealStatus",
    ]
  );

  useEffect(() => {
    setRows(currentMonthData?.rows || []);
    setHeaders(
      currentMonthData?.headers || [
        "date",
        "customerName",
        "contactName",
        "phoneNumber",
        "status1",
        "status2",
        "status3",
        "totalAmount",
        "preTaxAmount",
        "dealStatus",
      ]
    );
  }, [currentMonthData]);

  const handleInputChange = (index, field, value) => {
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setRows(updatedRows);

    dispatch({
      type: `${month}_sales_data`,
      payload: { rows: updatedRows, headers },
    });
  };

  const addRow = () => {
    setRows([
      ...rows,
      headers.reduce((acc, header) => ({ ...acc, [header]: "" }), {}),
    ]);
  };

  const addColumn = () => {
    const newHeader = prompt("Enter column name:");
    if (newHeader) {
      const updatedHeaders = [...headers, newHeader];
      setHeaders(updatedHeaders);

      setRows(rows.map((row) => ({ ...row, [newHeader]: "" })));

      dispatch({
        type: `${month}_sales_data`,
        payload: { rows, headers: updatedHeaders },
      });
    }
  };

  const saveData = (e) => {
    e.preventDefault();
    dispatch({
      type: `${month}_sales_data`,
      payload: { rows, headers },
    });
    alert("Data saved successfully!");

    document.getElementById("salesForm").reset();
    setRows([]);
  };

  return (
    <Fragment>

      <ProcessesSales/>
      <div className="container">
        <MonthSelectSales
          text={month_list}
          setIndex={setIndex}
          resetForm={"salesForm"}
          months={months}
        />
      </div>
      <form id="salesForm" onSubmit={saveData}>
        <div className="sales-management-container">
          <SalesTable
            rows={rows}
            headers={headers}
            handleInputChange={handleInputChange}
            currency={currency}
          />
          <button
            type="button"
            className="button-add-column"
            onClick={addColumn}
          >
            Add Column
          </button>
          <button type="button" className="button-sales" onClick={addRow}>
            +
          </button>
          <div className="button-save-container">
            <button type="submit" className="button-save-all text_center">
              Save
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  );
}

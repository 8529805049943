import React from "react";
import "./Artical.css";
import { useTranslation } from "react-i18next";

function ProcessesSales() {
  const { t } = useTranslation('SalesTraining');

  return (
    <div className="paragraph">
      <p>
        {t("sales_process_text_1")}
        <br />
        {t("sales_process_text_2")}
        <br />
        {t("sales_process_text_3")}
        <br />
        {t("sales_process_text_4")}
        <br />
        {t("sales_process_text_5")}
        <br />
        {t("sales_process_text_6")}
        <br />
        {t("sales_process_text_7")}
        <br />
        {t("sales_process_text_8")}
        <br />
        {t("sales_process_text_9")}
        <br />
        {t("sales_process_text_10")}
        <br />
        {t("sales_process_text_11")}
        <br />
        {t("sales_process_text_12")}
        <br />
        {t("sales_process_text_13")}
        <br />
        {t("sales_process_text_14")}
        <br />
        {t("sales_process_text_15")}
        <br />
        {t("sales_process_text_16")}
        <br />
        {t("sales_process_text_17")}
        <br />
        {t("sales_process_text_18")}
        <br />
        {t("sales_process_text_19")}
        <ul>
          <li>{t("sales_process_text_20")}</li>
          <li>{t("sales_process_text_21")}</li>
          <li>{t("sales_process_text_22")}</li>
          <li>{t("sales_process_text_23")}</li>
        </ul>
        <br />
        {t("sales_process_text_24")}
      </p>
    </div>
  );
}

export default ProcessesSales;

import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./MonthSelectSales.css";

function MonthSelectSales(props) {
  const { text, setIndex, resetForm, months } = props;
  const dispatch = useDispatch();
  const divs = document.querySelectorAll(".month");
  const theMonth = useSelector((state) => state.sales);

  const saveCurrentMonthData = () => {
    const currentMonth = theMonth.month;
    const currentData = theMonth[currentMonth];
    if (currentData) {
      dispatch({
        type: `${currentMonth}_sales_data`,
        payload: currentData,
      });
    }
  };

  return (
    <Fragment>

      <div className="container_grid_sales_12 text_center ">

        {months.map((mon, i) => (
          <Fragment key={i}>
            <p
              className="month"
              id={mon}
              onClick={(e) => {
                if (!(e.target.id === "Total" || e.target.id === "Average")) {
                  // Save current month's data before switching
                  saveCurrentMonthData();

                  // Reset form if exists
                  const formElement = document.getElementById(resetForm);
                  if (formElement) {
                    formElement.reset();
                  }

                  // Remove 'picked' class from all months
                  divs.forEach((div) => {
                    div.classList.remove("picked");
                  });

                  // Update current month in state
                  dispatch({ type: "month", payload: mon });
                  setIndex(i);

                  // Update rows with the selected month's data
                  if (theMonth[mon]) {
                    dispatch({
                      type: `${mon}_sales_data`,
                      payload: theMonth[mon],
                    });
                  } else {
                    console.warn(`No data found for month: ${mon}`);
                  }

                  // Mark the selected month
                  e.currentTarget.classList.add("picked");
                }
              }}
            >
              {text[i]}
            </p>
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
}

export default MonthSelectSales;

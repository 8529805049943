const defaultAppState = {
  volume: 0.1,
  Video_open: true,
};

export const appReducer = (state = defaultAppState, action) => {
  switch (action.type) {
    case "mute":
      return { ...state, volume: action.payload };
    case "Video_open":
      return { ...state, Video_open: action.payload };
    default:
      return state;
  }
};

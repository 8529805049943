import React from 'react'
import { useTranslation } from 'react-i18next';
import "./reasonSelect.css"

function ReasonSelect(props) {
  // const [selectedOption, setSelectedOption] = useState("1");
  const { t } = useTranslation("Incom");
  const { id,handleSelectChange, aValue} = props;
  return (
    <select
    id={id}
    name={"reason_select"} value={aValue}
    onChange={(e)=>{
      handleSelectChange(e);
      // setSelectedOption(e.target.value)
    }}
    className='reasonSelect'>
<option value="1">{t("General")}</option>
<option value="2">{t("Marketing")}</option>
<option value="3">{t("Providers")}</option>
<option value="4">{t("Salaries")}</option>
<option value="5">{t("Bank")}</option>
<option value="6">{t("Credit_card")}</option>
<option value="7">{t("Income_tax")}</option>
<option value="8">{t("Vat")}</option>
<option value="9">{t("Social_security")}</option>
<option value="10">{t("Book_keeping")}</option>
<option value="11">{t("Private")}</option>
<option value="12">{t("Maintenance")}</option>
<option value="13">{t("Customer")}</option>

  </select>
  )
}

export default ReasonSelect;

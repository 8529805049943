import React from 'react'
import { useDispatch } from 'react-redux';

function ResetButton(props) {
  const { text, month, value, setIndex,setAddInputs } = props;
  const dispatch = useDispatch();


  const reset = () => {
    dispatch({ type: month, payload: value });
    setAddInputs([]);
    setIndex(13);
    // if(month === "Default")dispatch({ type: "pull_fixed_expenses" });
    if (month !== "select month") {
      dispatch({ type: "Total" });
      dispatch({ type: "Average" });
      dispatch({ type: "reset_f" });
    }
    return;
  };

  const resetButtonFunc = (e) => {
    e.preventDefault();
    reset();
    document.getElementById("financForm").reset();
    return;
  }
  return (
    <div>
      <button type='button' className='button' onClick={(e) => {e.preventDefault(); resetButtonFunc(e) }}>{text}</button>
    </div>
  )
}

export default ResetButton;
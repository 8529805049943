import React, { useState } from 'react';

function SmallTable({ month, key }) {
  const titels1 = [
    "Total budget",
    "Retainer for suppliers",
    "Cost of marketing staff"
  ];

  const titels2 = [
    "Amount of leads",
    "Relevant leads",
    "Total of closed deals",
    "Income from leads"
  ];

  // const titels3 = [
  //   "Marketing effectiveness for leads",
  //   "Marketing effectiveness for sales",
  //   "Sales efficiency"
  // ];

  const [budgetValues, setBudgetValues] = useState({
    totalBudget: 0,
    retainerSuppliers: 0,
    marketingStaff: 0,
    TM: 0
  });

  const [marketingValues, setMarketingValues] = useState({
    TL: 0,
    TR: 0,
    TA: 0,
    TI: 0
  });

  const handleBudgetChange = (e, key) => {
    const newValue = parseFloat(e.target.value) || 0;
    const newBudgetValues = { ...budgetValues, [key]: newValue };

    // Calculate the total marketing value (TM)
    newBudgetValues.TM = newBudgetValues.totalBudget
                                   + newBudgetValues.retainerSuppliers
                                   + newBudgetValues.marketingStaff;

    setBudgetValues(newBudgetValues);
  };

  const handleMarketingChange = (e, key) => {
    const newValue = parseFloat(e.target.value) || 0;
    const newMarketingValues = { ...marketingValues, [key]: newValue };
    setMarketingValues(newMarketingValues);
  };

  // Calculations for titles3
  const calculateMarketingEffectivenessLeads = () => {
    return marketingValues.TL ? (marketingValues.TR / marketingValues.TL) * 100 : 0;
  };

  const calculateMarketingEffectivenessSales = () => {
    if (budgetValues.TM === 0) {
      return 0.00;
    }

    if (marketingValues.TI < budgetValues.TM && marketingValues.TI !== 0) {
      return (1 - (marketingValues.TI / budgetValues.TM)) * 100;
    } else {
      return (marketingValues.TI / budgetValues.TM) * 100;
    }
  };

  const calculateSalesEfficiency = () => {
    return marketingValues.TR ? (marketingValues.TA / marketingValues.TR) * 100 : 0;
  };

  return (
    <>
      <input className="input-table-f" defaultValue={month} disabled />

      {titels1.map((title, i) => (
        <input
          key={i}
          className="input-table-f"
          defaultValue={i === 0 ? budgetValues.totalBudget
                         : i === 1 ? budgetValues.retainerSuppliers
                         : budgetValues.marketingStaff}
          type="number"
          onChange={(e) => handleBudgetChange(e,
                       i === 0 ? 'totalBudget'
                       : i === 1 ? 'retainerSuppliers'
                       : 'marketingStaff')}
        />
      ))}

      {/* Display the total marketing value (TM) */}
      <input
        className="input-table-f"
        value={budgetValues.TM}
        disabled
      />

      {titels2.map((title, i) => (
        <input
          key={i}
          className="input-table-f"
          defaultValue={i === 0 ? marketingValues.TL
                         : i === 1 ? marketingValues.TR
                         : i === 2 ? marketingValues.TA
                         : marketingValues.TI}
          type="number"
          onChange={(e) => handleMarketingChange(e,
                       i === 0 ? 'TL'
                       : i === 1 ? 'TR'
                       : i === 2 ? 'TA'
                       : 'TI')}
        />
      ))}

      {/* The three last calculations with percentage signs */}
      <input
        className="input-table-f"
        value={`${calculateMarketingEffectivenessLeads().toFixed(2)}%`}
        type="text"
        disabled
      />
      <input
        className="input-table-f"
        value={`${calculateMarketingEffectivenessSales().toFixed(2)}%`}
        type="text"
        disabled
      />
      <input
        className="input-table-f"
        value={`${calculateSalesEfficiency().toFixed(2)}%`}
        type="text"
        disabled
      />
    </>
  );
}

export default SmallTable;

import React, { useState } from "react";
import "./SupplierTable.css";

function SupplierTable({ onSave, supplierHeader, amountHeader ,saveButtonText}) {
  const [rows, setRows] = useState([{ name: "", amount: "" }]);

  const addRow = () => {
    setRows([...rows, { name: "", amount: "" }]);
  };

  const handleInputChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
  };

  const handleSave = () => {
    const total = rows.reduce(
      (acc, row) => acc + parseFloat(row.amount || 0),
      0
    );
    onSave(total);
  };

  return (
    <div className="expandable-table">
      <div className="table-grid">
        <div className="table-header">{supplierHeader}</div>
        <div className="table-header">{amountHeader}</div>
        {rows.map((row, index) => (
          <React.Fragment key={index}>
            <input
              type="text"
              value={row.name}
              onChange={(e) => handleInputChange(index, "name", e.target.value)}
              className="table-input"
            />
            <input
              type="number"
              value={row.amount}
              onChange={(e) =>
                handleInputChange(index, "amount", e.target.value)
              }
              className="table-input"
            />
          </React.Fragment>
        ))}
      </div>
      <button type="button" onClick={addRow} className="add-button">
        +
      </button>
      <button type="button" onClick={handleSave} className="save-button">
        {saveButtonText}
      </button>
    </div>
  );
}

export default SupplierTable;

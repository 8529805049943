import * as XLSX from 'xlsx'
// function to flat objcet inside an object
function flattenObject(obj) {
    const result = {};

    function flatten(curObj, path) {
      for (const key in curObj) {
        if(typeof(curObj[key]) !== 'undefined'){
        const value = curObj[key];
        const newPath = path ? path + '.' + key : key;

        if (typeof value === 'object' && value !== null) {
          flatten(value, newPath);
        } else {
          result[newPath] = value;
        }
            }    }
    }

    flatten(obj, '');
    return result;
  }

export function handleExportFistPage(data,name){
    const flatData = data.map((obj)=>{
      return flattenObject(obj)
    })
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(flatData);
    worksheet['!cols'] = Array(50).fill({ width: 30});
    XLSX.utils.book_append_sheet(workbook, worksheet, name);
    // יצירת הקובץ
    XLSX.writeFile(workbook, `${name}.xlsx`);

}
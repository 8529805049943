import React, { Fragment } from 'react'
import "./IndicatorsInput.css"
function IndicatorsInput(props) {
    const {state, setState, id} = props;
    const addIndicator = (e)=> {
        setState([...state.slice(0, id), {...state[id], name:e.target.value},...state.slice(1 + id)]);
      }
  return (
    <Fragment>
        <input type="text" list="indicators"  className='indicators' placeholder={'pick your own indicators'} onBlur={addIndicator}/>
    </Fragment>
  )
}

export default IndicatorsInput
import React, { Fragment } from 'react'
import { useTranslation } from "react-i18next";
import "./GoalsSelect.css"
function GoalsSelect(props) {
  const {state, setState, id} = props;
    const { t} = useTranslation(["WorkPlan"]);
    const addGoal = (e)=> {
      setState([...state.slice(0, id), {...state[id], name:e.target.value},...state.slice(1 + id)]);
    };
  return (
    <Fragment>

<input type="text" list="goals"  className='goals' placeholder={t('increasing_sales_turnover_regularly')} onBlur={addGoal}/>
<datalist id="goals">
  <option>{t('increasing_sales_turnover_regularly')}</option>
  <option>{t('stability_in_the_business_structure')}</option>
  <option>{t('improving_quality_of_life')}</option>
  <option>{t('positioning_and_branding_as_a_leader_in_selected_niches')}</option>
  <option>{t('importing_complementary_products')}</option>
  <option>{t('increasing_the_profit_line')}</option>
  <option>{t('passive_income_generation')}</option>
  <option>{t('strengthening_reputation')}</option>
  <option>{t('manufacturing_the_best')}</option>
  <option>{t('improving_project_management')}</option>
  <option>{t('lead_in_customer_satisfaction')}</option>
  <option>{t('to_gain_an_image')}</option>
  <option>{t('streamlining_to_increase_production_yields')}</option>
  <option>{t('streamlining_the_procurement_of_purchases_with_suppliers')}</option>
  <option>{t('reduction_of_open_debts')}</option>
  <option>{t('establishment_of_a_franchise_network')}</option>
  <option>{t('stabilizing_the_cash_flow')}</option>
  <option>{t('development_of_new_products')}</option>
  <option>{t('stabilizing_and_improving')}</option>
  <option>{t('obtaining_the_ISO_standard')}</option>
  <option>{t('reducing_the_amount_of_employee_abandonment')}</option>
  <option>{t('increase_in_private_customers')}</option>
  <option>{t('growth_in_institutional_clients')}</option>
  <option>{t('penetration_into_a_new_market/new_markets')}</option>
  <option>{t('reducing_breakdowns_and_repetitive_work')}</option>
</datalist>
    </Fragment>
  )
}

export default GoalsSelect;
const defaultFinanceState = {
  month: "select month",
  product_revenues: 0,
  service_revenues: 0,
  inventory_cost: 0,
  suplier_expences: 0,
  salary_expenses: 0,
  insurance_expenses: 0,
  employee_expenses: 0,
  compensation_expenses: 0,
  fuel_expences: 0,
  business_maintenance_expences: 0,
  offices_expences: 0,
  phone_expences: 0,
  electricity_expences: 0,
  parking_expences: 0,
  mail_expences: 0,
  gifts_expences: 0,
  internet_expences: 0,
  computer_expences: 0,
  business_consulting_expences: 0,
  dues_expences: 0,
  work_expences: 0,
  advertising_expences: 0,
  call_center_expences: 0,
  cash_register_expences: 0,
  equipment_expences: 0,
  credit_expences: 0,
  property_expences: 0,
  fines_expences: 0,
  hosting_expences: 0,
  work_clothes_expences: 0,
  efreshments_expences: 0,
  bookkeeping_expences: 0,
  training_expences: 0,
  bank_fees_expences: 0,
  loans_expences: 0,
  credit_fees_expences: 0,
  extra_expense: {},
  balance_point:0,
  Default: {
  balance_point:0,
  total:0,
  total_Income:0,
  inventory_and_supplier_expenses:0,
  gross_profit_prtcentage:0,
  salary_and_related_expenses:0,
  salary_and_related_expenses_prtcentage_from_income:0,
  operational_and_managment:0,
  operational_and_managment_prtcentage_from_income:0,
  financial_expenses:0,
  financial_expenses_prtcentage_from_income:0,
  product_revenues: 0,
  service_revenues: 0,
  inventory_cost: 0,
  suplier_expences: 0,
  salary_expenses: 0,
  insurance_expenses: 0,
  employee_expenses: 0,
  compensation_expenses: 0,
  fuel_expences: 0,
  business_maintenance_expences: 0,
  offices_expences: 0,
  phone_expences: 0,
  electricity_expences: 0,
  parking_expences: 0,
  mail_expences: 0,
  gifts_expences: 0,
  internet_expences: 0,
  computer_expences: 0,
  business_consulting_expences: 0,
  dues_expences: 0,
  work_expences: 0,
  advertising_expences: 0,
  call_center_expences: 0,
  cash_register_expences: 0,
  equipment_expences: 0,
  credit_expences: 0,
  property_expences: 0,
  fines_expences: 0,
  hosting_expences: 0,
  work_clothes_expences: 0,
  efreshments_expences: 0,
  bookkeeping_expences: 0,
  training_expences: 0,
  bank_fees_expences: 0,
  loans_expences: 0,
  credit_fees_expences: 0,
  extra_expense: {},
   },
  January: {
    month:"January",
    active:false,
    balance_point:0,
  total:0,
  total_Income:0,
  inventory_and_supplier_expenses:0,
  gross_profit_prtcentage:0,
  salary_and_related_expenses:0,
  salary_and_related_expenses_prtcentage_from_income:0,
  operational_and_managment:0,
  operational_and_managment_prtcentage_from_income:0,
  financial_expenses:0,
  financial_expenses_prtcentage_from_income:0,
  product_revenues: 0,
  service_revenues: 0,
  inventory_cost: 0,
  suplier_expences: 0,
  salary_expenses: 0,
  insurance_expenses: 0,
  employee_expenses: 0,
  compensation_expenses: 0,
  fuel_expences: 0,
  business_maintenance_expences: 0,
  offices_expences: 0,
  phone_expences: 0,
  electricity_expences: 0,
  parking_expences: 0,
  mail_expences: 0,
  gifts_expences: 0,
  internet_expences: 0,
  computer_expences: 0,
  business_consulting_expences: 0,
  dues_expences: 0,
  work_expences: 0,
  advertising_expences: 0,
  call_center_expences: 0,
  cash_register_expences: 0,
  equipment_expences: 0,
  credit_expences: 0,
  property_expences: 0,
  fines_expences: 0,
  hosting_expences: 0,
  work_clothes_expences: 0,
  efreshments_expences: 0,
  bookkeeping_expences: 0,
  training_expences: 0,
  bank_fees_expences: 0,
  loans_expences: 0,
  credit_fees_expences: 0,
  extra_expense: {},
   },
  February: {
  month:"February",
  active:false,
  balance_point:0,
  total:0,
  total_Income:0,
  inventory_and_supplier_expenses:0,
  gross_profit_prtcentage:0,
  salary_and_related_expenses:0,
  salary_and_related_expenses_prtcentage_from_income:0,
  operational_and_managment:0,
  operational_and_managment_prtcentage_from_income:0,
  financial_expenses:0,
  financial_expenses_prtcentage_from_income:0,
  product_revenues: 0,
  service_revenues: 0,
  inventory_cost: 0,
  suplier_expences: 0,
  salary_expenses: 0,
  insurance_expenses: 0,
  employee_expenses: 0,
  compensation_expenses: 0,
  fuel_expences: 0,
  business_maintenance_expences: 0,
  offices_expences: 0,
  phone_expences: 0,
  electricity_expences: 0,
  parking_expences: 0,
  mail_expences: 0,
  gifts_expences: 0,
  internet_expences: 0,
  computer_expences: 0,
  business_consulting_expences: 0,
  dues_expences: 0,
  work_expences: 0,
  advertising_expences: 0,
  call_center_expences: 0,
  cash_register_expences: 0,
  equipment_expences: 0,
  credit_expences: 0,
  property_expences: 0,
  fines_expences: 0,
  hosting_expences: 0,
  work_clothes_expences: 0,
  efreshments_expences: 0,
  bookkeeping_expences: 0,
  training_expences: 0,
  bank_fees_expences: 0,
  loans_expences: 0,
  credit_fees_expences: 0,
  extra_expense: {},
   },
  March: {
    month:"March",
    active:false,
    balance_point:0,
  total:0,
  total_Income:0,
  inventory_and_supplier_expenses:0,
  gross_profit_prtcentage:0,
  salary_and_related_expenses:0,
  salary_and_related_expenses_prtcentage_from_income:0,
  operational_and_managment:0,
  operational_and_managment_prtcentage_from_income:0,
  financial_expenses:0,
  financial_expenses_prtcentage_from_income:0,
  product_revenues: 0,
  service_revenues: 0,
  inventory_cost: 0,
  suplier_expences: 0,
  salary_expenses: 0,
  insurance_expenses: 0,
  employee_expenses: 0,
  compensation_expenses: 0,
  fuel_expences: 0,
  business_maintenance_expences: 0,
  offices_expences: 0,
  phone_expences: 0,
  electricity_expences: 0,
  parking_expences: 0,
  mail_expences: 0,
  gifts_expences: 0,
  internet_expences: 0,
  computer_expences: 0,
  business_consulting_expences: 0,
  dues_expences: 0,
  work_expences: 0,
  advertising_expences: 0,
  call_center_expences: 0,
  cash_register_expences: 0,
  equipment_expences: 0,
  credit_expences: 0,
  property_expences: 0,
  fines_expences: 0,
  hosting_expences: 0,
  work_clothes_expences: 0,
  efreshments_expences: 0,
  bookkeeping_expences: 0,
  training_expences: 0,
  bank_fees_expences: 0,
  loans_expences: 0,
  credit_fees_expences: 0,
  extra_expense: {},
   },
  April: {
    month:"April",
    active:false,
    balance_point:0,
  total:0,
  total_Income:0,
  inventory_and_supplier_expenses:0,
  gross_profit_prtcentage:0,
  salary_and_related_expenses:0,
  salary_and_related_expenses_prtcentage_from_income:0,
  operational_and_managment:0,
  operational_and_managment_prtcentage_from_income:0,
  financial_expenses:0,
  financial_expenses_prtcentage_from_income:0,
    product_revenues: 0,
  service_revenues: 0,
  inventory_cost: 0,
  suplier_expences: 0,
  salary_expenses: 0,
  insurance_expenses: 0,
  employee_expenses: 0,
  compensation_expenses: 0,
  fuel_expences: 0,
  business_maintenance_expences: 0,
  offices_expences: 0,
  phone_expences: 0,
  electricity_expences: 0,
  parking_expences: 0,
  mail_expences: 0,
  gifts_expences: 0,
  internet_expences: 0,
  computer_expences: 0,
  business_consulting_expences: 0,
  dues_expences: 0,
  work_expences: 0,
  advertising_expences: 0,
  call_center_expences: 0,
  cash_register_expences: 0,
  equipment_expences: 0,
  credit_expences: 0,
  property_expences: 0,
  fines_expences: 0,
  hosting_expences: 0,
  work_clothes_expences: 0,
  efreshments_expences: 0,
  bookkeeping_expences: 0,
  training_expences: 0,
  bank_fees_expences: 0,
  loans_expences: 0,
  credit_fees_expences: 0,
  extra_expense: {},
   },
  May: {
    month:"May",
    active:false,  
    balance_point:0,
  total:0,
  total_Income:0,
  inventory_and_supplier_expenses:0,
  gross_profit_prtcentage:0,
  salary_and_related_expenses:0,
  salary_and_related_expenses_prtcentage_from_income:0,
  operational_and_managment:0,
  operational_and_managment_prtcentage_from_income:0,
  financial_expenses:0,
  financial_expenses_prtcentage_from_income:0,
    product_revenues: 0,
  service_revenues: 0,
  inventory_cost: 0,
  suplier_expences: 0,
  salary_expenses: 0,
  insurance_expenses: 0,
  employee_expenses: 0,
  compensation_expenses: 0,
  fuel_expences: 0,
  business_maintenance_expences: 0,
  offices_expences: 0,
  phone_expences: 0,
  electricity_expences: 0,
  parking_expences: 0,
  mail_expences: 0,
  gifts_expences: 0,
  internet_expences: 0,
  computer_expences: 0,
  business_consulting_expences: 0,
  dues_expences: 0,
  work_expences: 0,
  advertising_expences: 0,
  call_center_expences: 0,
  cash_register_expences: 0,
  equipment_expences: 0,
  credit_expences: 0,
  property_expences: 0,
  fines_expences: 0,
  hosting_expences: 0,
  work_clothes_expences: 0,
  efreshments_expences: 0,
  bookkeeping_expences: 0,
  training_expences: 0,
  bank_fees_expences: 0,
  loans_expences: 0,
  credit_fees_expences: 0,
  extra_expense: {},
   },
  June: {
    month:"June",
    active:false,
    balance_point:0,  
  total:0,
  total_Income:0,
  inventory_and_supplier_expenses:0,
  gross_profit_prtcentage:0,
  salary_and_related_expenses:0,
  salary_and_related_expenses_prtcentage_from_income:0,
  operational_and_managment:0,
  operational_and_managment_prtcentage_from_income:0,
  financial_expenses:0,
  financial_expenses_prtcentage_from_income:0,
    product_revenues: 0,
  service_revenues: 0,
  inventory_cost: 0,
  suplier_expences: 0,
  salary_expenses: 0,
  insurance_expenses: 0,
  employee_expenses: 0,
  compensation_expenses: 0,
  fuel_expences: 0,
  business_maintenance_expences: 0,
  offices_expences: 0,
  phone_expences: 0,
  electricity_expences: 0,
  parking_expences: 0,
  mail_expences: 0,
  gifts_expences: 0,
  internet_expences: 0,
  computer_expences: 0,
  business_consulting_expences: 0,
  dues_expences: 0,
  work_expences: 0,
  advertising_expences: 0,
  call_center_expences: 0,
  cash_register_expences: 0,
  equipment_expences: 0,
  credit_expences: 0,
  property_expences: 0,
  fines_expences: 0,
  hosting_expences: 0,
  work_clothes_expences: 0,
  efreshments_expences: 0,
  bookkeeping_expences: 0,
  training_expences: 0,
  bank_fees_expences: 0,
  loans_expences: 0,
  credit_fees_expences: 0,
  extra_expense: {},
   },
  July: {
    month:"July",
    active:false,
    balance_point:0,
  total:0,
  total_Income:0,
  inventory_and_supplier_expenses:0,
  gross_profit_prtcentage:0,
  salary_and_related_expenses:0,
  salary_and_related_expenses_prtcentage_from_income:0,
  operational_and_managment:0,
  operational_and_managment_prtcentage_from_income:0,
  financial_expenses:0,
  financial_expenses_prtcentage_from_income:0,
    product_revenues: 0,
  service_revenues: 0,
  inventory_cost: 0,
  suplier_expences: 0,
  salary_expenses: 0,
  insurance_expenses: 0,
  employee_expenses: 0,
  compensation_expenses: 0,
  fuel_expences: 0,
  business_maintenance_expences: 0,
  offices_expences: 0,
  phone_expences: 0,
  electricity_expences: 0,
  parking_expences: 0,
  mail_expences: 0,
  gifts_expences: 0,
  internet_expences: 0,
  computer_expences: 0,
  business_consulting_expences: 0,
  dues_expences: 0,
  work_expences: 0,
  advertising_expences: 0,
  call_center_expences: 0,
  cash_register_expences: 0,
  equipment_expences: 0,
  credit_expences: 0,
  property_expences: 0,
  fines_expences: 0,
  hosting_expences: 0,
  work_clothes_expences: 0,
  efreshments_expences: 0,
  bookkeeping_expences: 0,
  training_expences: 0,
  bank_fees_expences: 0,
  loans_expences: 0,
  credit_fees_expences: 0,
  extra_expense: {},
   },
  August: {
    month:"August",
    active:false,
    balance_point:0,
  total:0,
  total_Income:0,
  inventory_and_supplier_expenses:0,
  gross_profit_prtcentage:0,
  salary_and_related_expenses:0,
  salary_and_related_expenses_prtcentage_from_income:0,
  operational_and_managment:0,
  operational_and_managment_prtcentage_from_income:0,
  financial_expenses:0,
  financial_expenses_prtcentage_from_income:0,
    product_revenues: 0,
  service_revenues: 0,
  inventory_cost: 0,
  suplier_expences: 0,
  salary_expenses: 0,
  insurance_expenses: 0,
  employee_expenses: 0,
  compensation_expenses: 0,
  fuel_expences: 0,
  business_maintenance_expences: 0,
  offices_expences: 0,
  phone_expences: 0,
  electricity_expences: 0,
  parking_expences: 0,
  mail_expences: 0,
  gifts_expences: 0,
  internet_expences: 0,
  computer_expences: 0,
  business_consulting_expences: 0,
  dues_expences: 0,
  work_expences: 0,
  advertising_expences: 0,
  call_center_expences: 0,
  cash_register_expences: 0,
  equipment_expences: 0,
  credit_expences: 0,
  property_expences: 0,
  fines_expences: 0,
  hosting_expences: 0,
  work_clothes_expences: 0,
  efreshments_expences: 0,
  bookkeeping_expences: 0,
  training_expences: 0,
  bank_fees_expences: 0,
  loans_expences: 0,
  credit_fees_expences: 0,
  extra_expense: {},
   },
  September: {
    month:"September",
    active:false,
    balance_point:0,
  total:0,
  total_Income:0,
  inventory_and_supplier_expenses:0,
  gross_profit_prtcentage:0,
  salary_and_related_expenses:0,
  salary_and_related_expenses_prtcentage_from_income:0,
  operational_and_managment:0,
  operational_and_managment_prtcentage_from_income:0,
  financial_expenses:0,
  financial_expenses_prtcentage_from_income:0,
    product_revenues: 0,
  service_revenues: 0,
  inventory_cost: 0,
  suplier_expences: 0,
  salary_expenses: 0,
  insurance_expenses: 0,
  employee_expenses: 0,
  compensation_expenses: 0,
  fuel_expences: 0,
  business_maintenance_expences: 0,
  offices_expences: 0,
  phone_expences: 0,
  electricity_expences: 0,
  parking_expences: 0,
  mail_expences: 0,
  gifts_expences: 0,
  internet_expences: 0,
  computer_expences: 0,
  business_consulting_expences: 0,
  dues_expences: 0,
  work_expences: 0,
  advertising_expences: 0,
  call_center_expences: 0,
  cash_register_expences: 0,
  equipment_expences: 0,
  credit_expences: 0,
  property_expences: 0,
  fines_expences: 0,
  hosting_expences: 0,
  work_clothes_expences: 0,
  efreshments_expences: 0,
  bookkeeping_expences: 0,
  training_expences: 0,
  bank_fees_expences: 0,
  loans_expences: 0,
  credit_fees_expences: 0,
  extra_expense: {},
   },
  October: {
    month:"October",
    active:false,
    balance_point:0,
  total:0,
  total_Income:0,
  inventory_and_supplier_expenses:0,
  gross_profit_prtcentage:0,
  salary_and_related_expenses:0,
  salary_and_related_expenses_prtcentage_from_income:0,
  operational_and_managment:0,
  operational_and_managment_prtcentage_from_income:0,
  financial_expenses:0,
  financial_expenses_prtcentage_from_income:0,
    product_revenues: 0,
  service_revenues: 0,
  inventory_cost: 0,
  suplier_expences: 0,
  salary_expenses: 0,
  insurance_expenses: 0,
  employee_expenses: 0,
  compensation_expenses: 0,
  fuel_expences: 0,
  business_maintenance_expences: 0,
  offices_expences: 0,
  phone_expences: 0,
  electricity_expences: 0,
  parking_expences: 0,
  mail_expences: 0,
  gifts_expences: 0,
  internet_expences: 0,
  computer_expences: 0,
  business_consulting_expences: 0,
  dues_expences: 0,
  work_expences: 0,
  advertising_expences: 0,
  call_center_expences: 0,
  cash_register_expences: 0,
  equipment_expences: 0,
  credit_expences: 0,
  property_expences: 0,
  fines_expences: 0,
  hosting_expences: 0,
  work_clothes_expences: 0,
  efreshments_expences: 0,
  bookkeeping_expences: 0,
  training_expences: 0,
  bank_fees_expences: 0,
  loans_expences: 0,
  credit_fees_expences: 0,
  extra_expense: {},
   },
  November: {
    month:" November",
    active:false,
    balance_point:0,
  total:0,
  total_Income:0,
  inventory_and_supplier_expenses:0,
  gross_profit_prtcentage:0,
  salary_and_related_expenses:0,
  salary_and_related_expenses_prtcentage_from_income:0,
  operational_and_managment:0,
  operational_and_managment_prtcentage_from_income:0,
  financial_expenses:0,
  financial_expenses_prtcentage_from_income:0,
    product_revenues: 0,
  service_revenues: 0,
  inventory_cost: 0,
  suplier_expences: 0,
  salary_expenses: 0,
  insurance_expenses: 0,
  employee_expenses: 0,
  compensation_expenses: 0,
  fuel_expences: 0,
  business_maintenance_expences: 0,
  offices_expences: 0,
  phone_expences: 0,
  electricity_expences: 0,
  parking_expences: 0,
  mail_expences: 0,
  gifts_expences: 0,
  internet_expences: 0,
  computer_expences: 0,
  business_consulting_expences: 0,
  dues_expences: 0,
  work_expences: 0,
  advertising_expences: 0,
  call_center_expences: 0,
  cash_register_expences: 0,
  equipment_expences: 0,
  credit_expences: 0,
  property_expences: 0,
  fines_expences: 0,
  hosting_expences: 0,
  work_clothes_expences: 0,
  efreshments_expences: 0,
  bookkeeping_expences: 0,
  training_expences: 0,
  bank_fees_expences: 0,
  loans_expences: 0,
  credit_fees_expences: 0,
  extra_expense: {},
   },
  December: {
    month:" December",
    active:false,
    balance_point:0,
  total:0,
  total_Income:0,
  inventory_and_supplier_expenses:0,
  gross_profit_prtcentage:0,
  salary_and_related_expenses:0,
  salary_and_related_expenses_prtcentage_from_income:0,
  operational_and_managment:0,
  operational_and_managment_prtcentage_from_income:0,
  financial_expenses:0,
  financial_expenses_prtcentage_from_income:0,
    product_revenues: 0,
  service_revenues: 0,
  inventory_cost: 0,
  suplier_expences: 0,
  salary_expenses: 0,
  insurance_expenses: 0,
  employee_expenses: 0,
  compensation_expenses: 0,
  fuel_expences: 0,
  business_maintenance_expences: 0,
  offices_expences: 0,
  phone_expences: 0,
  electricity_expences: 0,
  parking_expences: 0,
  mail_expences: 0,
  gifts_expences: 0,
  internet_expences: 0,
  computer_expences: 0,
  business_consulting_expences: 0,
  dues_expences: 0,
  work_expences: 0,
  advertising_expences: 0,
  call_center_expences: 0,
  cash_register_expences: 0,
  equipment_expences: 0,
  credit_expences: 0,
  property_expences: 0,
  fines_expences: 0,
  hosting_expences: 0,
  work_clothes_expences: 0,
  efreshments_expences: 0,
  bookkeeping_expences: 0,
  training_expences: 0,
  bank_fees_expences: 0,
  loans_expences: 0,
  credit_fees_expences: 0,
  extra_expense: {},
   },
  Total: {total:0, },
  Average:{total:0,},
};
// const names = Object.keys(defaultState);

export const financeReducer = (state = defaultFinanceState, action) => {
  switch (action.type) {
    case "month":
      return { ...state, month: action.payload };
    case "Average":
      return {...state, Average: {total:(+state.Total.total)/12}};
    case "Total":
      return { ...state , Total: {total:(+state.January.total + +state.February.total + +state.March.total + +state.April.total +
         +state.May.total + +state.July.total + +state.July.total + +state.August.total + +state.September.total + +state.October.total + +state.November.total  + +state.December.total)} };
         case "pull_fixed_expenses":
          return { ...state,
             January: {...state.Default, month:state.January.month},
             February: {...state.Default, month:state.February.month},
             March: {...state.Default, month:state.March.month},
             April: {...state.Default, month:state.April.month},
             May: {...state.Default, month:state.May.month},
             June: {...state.Default, month:state.June.month},
             July: {...state.Default, month:state.July.month},
             August: {...state.Default, month:state.August.month},
             September: {...state.Default, month:state.September.month},
             October: {...state.Default, month:state.October.month},
             November: {...state.Default, month:state.November.month},
             December: {...state.Default, month:state.December.month}};
         case "Default":
      return { ...state, Default: action.payload};
    case "January":
      return { ...state, January: action.payload};
    case "February":
      return { ...state, February: action.payload };
    case "March":
      return { ...state, March: action.payload };
    case "April":
      return { ...state, April: action.payload };
    case "May":
      return { ...state, May: action.payload };
    case "June":
      return { ...state, June: action.payload };
    case "July":
      return { ...state, July: action.payload };
    case "August":
      return { ...state, August: action.payload };
    case "September":
      return { ...state, September: action.payload };
    case "October":
      return { ...state, October: action.payload };
    case "November":
      return { ...state, November: action.payload };
    case "December":
      return { ...state, December: action.payload };
    case "product_revenues":
      return { ...state, product_revenues: action.payload };
    case "service_revenues":
      return { ...state, service_revenues: action.payload };
    case "income":
    case 'inventory_cost':
      return {...state, inventory_cost: action.payload };
    case 'suplier_expences':
      return {...state, suplier_expences: action.payload };
    case 'salary_expenses':
      return {...state, salary_expenses: action.payload };
    case 'insurance_expenses':
      return {...state, insurance_expenses: action.payload };
    case 'employee_expenses':
      return {...state, employee_expenses: action.payload };
    case 'compensation_expenses':
      return {...state, compensation_expenses: action.payload };
    case 'fuel_expences':
      return {...state, fuel_expences: action.payload };
    case 'business_maintenance_expences':
      return {...state, business_maintenance_expences: action.payload };
    case 'offices_expences':
      return {...state, offices_expences: action.payload };
    case 'phone_expences':
      return {...state, phone_expences: action.payload };
    case 'electricity_expences':
      return {...state, electricity_expences: action.payload };
    case 'parking_expences':
      return {...state, parking_expences: action.payload };
    case 'mail_expences':
      return {...state, mail_expences: action.payload };
    case 'gifts_expences':
      return {...state, gifts_expences: action.payload };
    case 'internet_expences':
      return {...state, internet_expences: action.payload };
    case 'computer_expences':
      return {...state, computer_expences: action.payload };
    case 'business_consulting_expences':
      return {...state, business_consulting_expences: action.payload };
    case 'dues_expences':
      return {...state, dues_expences: action.payload };
    case 'work_expences':
      return {...state, work_expences: action.payload };
    case 'advertising_expences':
      return {...state, advertising_expences: action.payload };
    case 'call_center_expences':
      return {...state, call_center_expences: action.payload };
    case 'cash_register_expences':
      return {...state, cash_register_expences: action.payload };
    case 'equipment_expences':
      return {...state, equipment_expences: action.payload };
    case 'credit_expences':
      return {...state, credit_expences: action.payload };
    case 'property_expences':
      return {...state, property_expences: action.payload };
    case 'fines_expences':
      return {...state, fines_expences: action.payload };
    case 'hosting_expences':
      return {...state, hosting_expences: action.payload };
    case 'work_clothes_expences':
      return {...state, work_clothes_expences: action.payload };
    case 'efreshments_expences':
      return {...state, efreshments_expences: action.payload };    
    case 'bookkeeping_expences':
      return {...state, bookkeeping_expences: action.payload };
    case 'training_expences':
      return {...state, training_expences: action.payload };
    case 'bank_fees_expences':
      return {...state, bank_fees_expences: action.payload };
    case 'loans_expences':
      return {...state, loans_expences: action.payload };
    case 'credit_fees_expences':
      return {...state, credit_fees_expences: action.payload };
    case 'extra_expense':
      return {...state, extra_expense:{...state.extra_expense,[action.payload.name]:action.payload.value} };
    case 'reset_f':
      return { 
        month: "select month",
        product_revenues: 0,
        service_revenues: 0,
        inventory_cost: 0,
        suplier_expences: 0,
        salary_expenses: 0,
        insurance_expenses: 0,
        employee_expenses: 0,
        compensation_expenses: 0,
        fuel_expences: 0,
        business_maintenance_expences: 0,
        offices_expences: 0,
        phone_expences: 0,
        electricity_expences: 0,
        parking_expences: 0,
        mail_expences: 0,
        gifts_expences: 0,
        internet_expences: 0,
        computer_expences: 0,
        business_consulting_expences: 0,
        dues_expences: 0,
        work_expences: 0,
        advertising_expences: 0,
        call_center_expences: 0,
        cash_register_expences: 0,
        equipment_expences: 0,
        credit_expences: 0,
        property_expences: 0,
        fines_expences: 0,
        hosting_expences: 0,
        work_clothes_expences: 0,
        efreshments_expences: 0,
        bookkeeping_expences: 0,
        training_expences: 0,
        bank_fees_expences: 0,
        loans_expences: 0,
        credit_fees_expences: 0,
        extra_expense: 0,
        January: state.January,
        February: state.February,
        March: state.March,
        April: state.April,
        May: state.May,
        June: state.June,
        July: state.July,
        August: state.August,
        September: state.September,
        October: state.October,
        November: state.November,
        December: state.December,
        Total: state.Total,
        Average: state.Average,
        Default: state.Default,
      };
    default:
      return state;
  }
};

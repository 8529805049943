import React from 'react'
import "./Artical.css"
import { useTranslation } from 'react-i18next';
// import { text } from '@fortawesome/fontawesome-svg-core';
function SalesCoaching() {
  const { t } = useTranslation('SalesTraining');
  return (
    <div className='paragraph'>


      <p>
        <h3>{t('first_h')}</h3>
        {t('text_1')}
      </p>
      <p>{t('first_modal_h')}
        <br />
        <br />
        {t('first_modal_text_1')}
        <br />
        {t('first_modal_text_2')}
        <br />
        {t('first_modal_text_3')}
      </p>
      <p>
        {t("second_modal_h")}
        <br />
        <br />
        {t("second_modal_text_1")}
        <br />
        {t("second_modal_text_2")}
        <br />
        {t("second_modal_text_3")}
        <br />
        {t("second_modal_text_4")}
      </p>
      <p>
        {t("third_modal_h")}
        <br />
        <br />
        {t("third_modal_text_1")}
        <br />
        {t("third_modal_text_2")}
        <br />
        {t("third_modal_text_3")}
        <br />
        {t("third_modal_text_4")}
        <br />
        {t("third_modal_text_5")}
        <br />
        <br />
      </p>
      <p>
        {t("fourth_modal_h")}
        <br />
        <br />
        {t("fourth_modal_text_1")}
        <br />
        {t("fourth_modal_text_2")}
        <br />
        {t("fourth_modal_text_3")}
        <br />
        {t("fourth_modal_text_4")}
        <br />
        {t("fourth_modal_text_5")}

        <br />
        <br />

      </p>
    </div>
  )
}

export default SalesCoaching;
import React from "react";
import "./ActionBox.css";
function ActionBox(props) {
  const { number } = props;
  return (
    <div className="box">
      <div>
        <label htmlFor="responsibility">under the responsibility of :</label>
        <input name="responsibility" />
      </div>
      Action number: {number}
      <div className="action">
        <textarea className="action" />
      </div>
      <label htmlFor="time">
        time limt :
        <input name="time" />
      </label>
    </div>
  );
}

export default ActionBox;


export const budgetRows = [
  {
    id: "inventory_and_suppliers",
    text: "inventory_and_suppliers",
    initialPercentage: "inventory_and_suppliers_initialPercentage",
  },
  {
    id: "employee_salaries",
    text: "employee_salaries",
    initialPercentage: "employee_salaries_initialPercentage",
  },
  {
    id: "operation_costs",
    text: "operation_costs",
    initialPercentage: "operation_costs_initialPercentage",
  },
  {
    id: "repayments_of_loans",
    text: "repayments_of_loans",
    initialPercentage:"repayments_of_loans_initialPercentage",
  },
  // {
  //   id: "total",
  //   text: "total",
  //   // initialPercentage: 0.03,
  // },
];

import React, { Fragment, useEffect, useState } from "react";
import "./FirstPage.css";
import TextInputCell from "../../components/Inputs/TextInputCell/TextInputCell";
import TextInputCallExtra from "../../components/Inputs/TextInputExtra/TextInputExtra";
import MonthSelect from "../../components/Selectors/MonthSelect/MonthSelect";
import TextButtonCell from "../../components/Buttons/TextButtonCell/TextButtonCell";
// import TwelveInputCell from '../../components/Inputs/TweleveInputCell/TwelveInputCell'
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";
import ResetButton from "../../components/Buttons/ResetButton/ResetButton";
import { useTranslation } from "react-i18next";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { financeMonths } from "../../helpers/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExcelButton from "../../components/Buttons/ExcelButton/ExcelButton";
// import { firstGetReuqest } from '../FirstPage/FirstPageRequst'

import MonthTableRow from "../../components/Tables/MonthTable/MonthTableRow";
import MonthTableBP from "../../components/Tables/MonthTable/MonthTableBP";
// import TextToSpeech from "../../components/TextToSpeech/TextToSpeech";
import { handleExportFistPage } from "../../Excel/financeExcelFunction";
import { useSelector, useDispatch } from "react-redux";
import SupplierTable from "../../components/Tables/SupplierTable/SupplierTable";

function FirstPage() {
  const dispatch = useDispatch();

  const [inventoryTotal, setInventoryTotal] = useState(false);
  const [supplierTotal, setSupplierTotal] = useState(false);

  // firstGetReuqest()
  const [addInputs, setAddInputs] = useState([]);
  // const [video, setVideo] = useState(true);
  const currency = useSelector((state) => state.income.currency);

  const [index, setIndex] = useState([13]);
  const month = useSelector((state) => state.finance.month);

  const product_revenues = useSelector(
    (state) => state.finance.product_revenues
  );
  const service_revenues = useSelector(
    (state) => state.finance.service_revenues
  );

  const inventory_cost = useSelector((state) => state.finance.inventory_cost);
  const suplier_expences = useSelector(
    (state) => state.finance.suplier_expences
  );

  const salary_expenses = useSelector((state) => state.finance.salary_expenses);
  const insurance_expenses = useSelector(
    (state) => state.finance.insurance_expenses
  );
  const employee_expenses = useSelector(
    (state) => state.finance.employee_expenses
  );
  const compensation_expenses = useSelector(
    (state) => state.finance.compensation_expenses
  );

  const fuel_expences = useSelector((state) => state.finance.fuel_expences);
  const offices_expences = useSelector(
    (state) => state.finance.offices_expences
  );
  const electricity_expences = useSelector(
    (state) => state.finance.electricity_expences
  );
  const parking_expences = useSelector(
    (state) => state.finance.parking_expences
  );
  const phone_expences = useSelector((state) => state.finance.phone_expences);
  const mail_expences = useSelector((state) => state.finance.mail_expences);
  const gifts_expences = useSelector((state) => state.finance.gifts_expences);
  const internet_expences = useSelector(
    (state) => state.finance.internet_expences
  );
  const computer_expences = useSelector(
    (state) => state.finance.computer_expences
  );
  const business_consulting_expences = useSelector(
    (state) => state.finance.business_consulting_expences
  );
  const business_maintenance_expences = useSelector(
    (state) => state.finance.business_maintenance_expences
  );
  const dues_expences = useSelector((state) => state.finance.dues_expences);
  const work_expences = useSelector((state) => state.finance.work_expences);
  const advertising_expences = useSelector(
    (state) => state.finance.advertising_expences
  );
  const call_center_expences = useSelector(
    (state) => state.finance.call_center_expences
  );
  const cash_register_expences = useSelector(
    (state) => state.finance.cash_register_expences
  );
  const equipment_expences = useSelector(
    (state) => state.finance.equipment_expences
  );
  const credit_expences = useSelector((state) => state.finance.credit_expences);
  const property_expences = useSelector(
    (state) => state.finance.property_expences
  );
  const fines_expences = useSelector((state) => state.finance.fines_expences);
  const hosting_expences = useSelector(
    (state) => state.finance.hosting_expences
  );
  const work_clothes_expences = useSelector(
    (state) => state.finance.work_clothes_expences
  );
  const efreshments_expences = useSelector(
    (state) => state.finance.efreshments_expences
  );
  const bookkeeping_expences = useSelector(
    (state) => state.finance.bookkeeping_expences
  );
  const training_expences = useSelector(
    (state) => state.finance.training_expences
  );
  const extra_expense = useSelector((state) => state.finance.extra_expense);
  const January = useSelector((state) => state.finance.January);
  const February = useSelector((state) => state.finance.February);
  const March = useSelector((state) => state.finance.March);
  const April = useSelector((state) => state.finance.April);
  const May = useSelector((state) => state.finance.May);
  const June = useSelector((state) => state.finance.June);
  const July = useSelector((state) => state.finance.July);
  const August = useSelector((state) => state.finance.August);
  const September = useSelector((state) => state.finance.September);
  const October = useSelector((state) => state.finance.October);
  const November = useSelector((state) => state.finance.November);
  const December = useSelector((state) => state.finance.December);
  // const Default = useSelector(state => state.finance.Default);
  const theMonth = useSelector((state) => state.finance[month]);
  // console.log(January);
  // console.log(theMonth)
  // useEffect(() => {
  //     const handleBeforeUnload = (event) => {
  //       if (!event.defaultPrevented) { // Check if default behavior is already prevented
  //         const isLeaving = !window.location.origin || window.location.origin !== document.referrer;
  //         if (isLeaving) {
  //           event.preventDefault();
  //           event.returnValue = 'Are you sure you want to leave?';
  //         }
  //       }
  //     };

  //     window.addEventListener('beforeunload', handleBeforeUnload);

  //     return () => {
  //       window.removeEventListener('beforeunload', handleBeforeUnload);

  //     };
  //   }, []);
  useEffect(() => {
    if (theMonth) setAddInputs(Object.values(theMonth.extra_expense).slice(1));
  }, [theMonth]);
  // make the extra exoence object to array and then we adding all the values to make total
  const extra_expence_to_array = Object.values(extra_expense);
  const extra_expence_total = extra_expence_to_array.reduce(
    (start, value, index) => (index > 0 ? +start + +value : start),
    0
  );
  //
  const bank_fees_expences = useSelector(
    (state) => state.finance.bank_fees_expences
  );
  const loans_expences = useSelector((state) => state.finance.loans_expences);
  const credit_fees_expences = useSelector(
    (state) => state.finance.credit_fees_expences
  );

  const { t } = useTranslation("Finance");
  const month_list = t("month_text").split(",");
  const month_list_table = t("month_text_table").split(",");
  const x1 = +product_revenues + +service_revenues;
  const y = +inventory_cost + +suplier_expences;
  // const gp = (x1 - y);

  const gp_percentage = x1 > 0 ? ((y / x1) * 100).toFixed() : 0;

  const mc =
    +fuel_expences +
    +business_maintenance_expences +
    +offices_expences +
    +phone_expences +
    +electricity_expences +
    +parking_expences +
    +mail_expences +
    +gifts_expences +
    +internet_expences +
    +computer_expences +
    +business_consulting_expences +
    +work_expences +
    +advertising_expences +
    +call_center_expences +
    +cash_register_expences +
    +equipment_expences +
    +credit_expences +
    +property_expences +
    +fines_expences +
    +hosting_expences +
    +work_clothes_expences +
    +efreshments_expences +
    +bookkeeping_expences +
    +training_expences +
    +dues_expences +
    +extra_expence_total;

  const mc_percentage =
    +product_revenues + +service_revenues > 0
      ? (
          ((+fuel_expences +
            +business_maintenance_expences +
            +offices_expences +
            +phone_expences +
            +electricity_expences +
            +parking_expences +
            +mail_expences +
            +gifts_expences +
            +internet_expences +
            +computer_expences +
            +business_consulting_expences +
            +work_expences +
            +advertising_expences +
            +call_center_expences +
            +cash_register_expences +
            +equipment_expences +
            +credit_expences +
            +property_expences +
            +fines_expences +
            +hosting_expences +
            +work_clothes_expences +
            +efreshments_expences +
            +bookkeeping_expences +
            +training_expences +
            +dues_expences +
            +extra_expence_total) /
            (+product_revenues + +service_revenues)) *
          100
        ).toFixed()
      : 0;

  const sc =
    +salary_expenses +
    +insurance_expenses +
    +employee_expenses +
    +compensation_expenses;

  const sc_percentage =
    +product_revenues + +service_revenues > 0
      ? (
          ((+salary_expenses +
            +insurance_expenses +
            +employee_expenses +
            +compensation_expenses) /
            (+product_revenues + +service_revenues)) *
          100
        ).toFixed()
      : 0;
  const fc = +bank_fees_expences + +loans_expences + +credit_fees_expences;
  const fc_percentage =
    +product_revenues + +service_revenues > 0 ? ((fc / x1) * 100).toFixed() : 0;
  const fx_prtcentage =
    +product_revenues + +service_revenues > 0
      ? (
          ((+bank_fees_expences + +loans_expences + +credit_fees_expences) /
            (+product_revenues + +service_revenues)) *
          100
        ).toFixed()
      : 0;

  const bp = x1 > 0 && y !== x1 ? (+sc + +mc + +fc) / (1 - y / x1) : 0;

  const all_inputs = {
    product_revenues,
    service_revenues,
    inventory_cost,
    suplier_expences,
    fuel_expences,
    business_maintenance_expences,
    offices_expences,
    phone_expences,
    electricity_expences,
    parking_expences,
    mail_expences,
    gifts_expences,
    internet_expences,
    computer_expences,
    business_consulting_expences,
    work_expences,
    advertising_expences,
    call_center_expences,
    cash_register_expences,
    equipment_expences,
    credit_expences,
    property_expences,
    fines_expences,
    hosting_expences,
    work_clothes_expences,
    efreshments_expences,
    bookkeeping_expences,
    salary_expenses,
    insurance_expenses,
    employee_expenses,
    compensation_expenses,
    training_expences,
    dues_expences,
    extra_expense,
    bank_fees_expences,
    loans_expences,
    credit_fees_expences,
    total_Income: +product_revenues + +service_revenues,
    inventory_and_supplier_expenses: y,
    gross_profit_prtcentage: gp_percentage,
    balance_point: bp,
    salary_and_related_expenses: sc,
    salary_and_related_expenses_prtcentage_from_income: sc_percentage,
    financial_expenses: fc,
    operational_and_managment: mc,
    operational_and_managment_prtcentage_from_income: mc_percentage,
    financial_expenses_prtcentage_from_income: fx_prtcentage,
  };

  const total =
    +product_revenues +
    +service_revenues -
    (+inventory_cost +
      +suplier_expences +
      +fuel_expences +
      +business_maintenance_expences +
      +offices_expences +
      +phone_expences +
      +electricity_expences +
      +parking_expences +
      +mail_expences +
      +gifts_expences +
      +internet_expences +
      +computer_expences +
      +business_consulting_expences +
      +work_expences +
      +advertising_expences +
      +call_center_expences +
      +cash_register_expences +
      +equipment_expences +
      +credit_expences +
      +property_expences +
      +fines_expences +
      +hosting_expences +
      +work_clothes_expences +
      +efreshments_expences +
      +bookkeeping_expences +
      +salary_expenses +
      +insurance_expenses +
      +employee_expenses +
      +compensation_expenses +
      +training_expences +
      +dues_expences +
      +extra_expence_total +
      +bank_fees_expences +
      +loans_expences +
      +credit_fees_expences);
  const rows = [
    {
      id: "total_Income",
      text: t("Find_total_Income"),
    },
    {
      id: "inventory_and_supplier_expenses",
      text: t("Find_Inventory_and_supplier_expenses"),
    },
    {
      id: "salary_and_related_expenses",
      text: t("Find_salary_and_related_expenses"),
    },
    {
      id: "operational_and_managment",
      text: t("Find_operational_and_managment"),
    },
    {
      id: "financial_expenses",
      text: t("Find_financial_expenses"),
    },
    {
      id: "total",
      text: t("new_total"),
    },
  ];

  // const buttonMonth = (e) => {
  //     e.preventDefault();
  //     if(!(e.target.id === "Default")){
  //         dispatch({type:month,payload: {...Default,month:month} });
  //         document.getElementById('financForm').reset()
  //     }
  // }

  const updateInventoryCost = (total) => {
    setInventoryTotal(total);
    dispatch({
      type: month,
      payload: {
        ...theMonth,
        inventory_cost: total,
      },
    });
  };

  const updateSupplierExpenses = (total) => {
    setSupplierTotal(total);
    dispatch({
      type: month,
      payload: {
        ...theMonth,
        suplier_expences: total,
      },
    });
  };

  return (
    <Fragment>
      <div className="container" style={{ justifyContent: "start" }}>
        <div className="header_title1">
          {/* {t("Financial_plan_&_balance_point")} */}
        </div>
      </div>
      <div className="video-container_p">
        <VideoNewModal videoUrl={t("video_url")} />
      </div>
      <ExcelButton
        data={[
          January,
          February,
          March,
          April,
          May,
          June,
          July,
          August,
          September,
          October,
          November,
          December,
        ]}
        name={"Financial-plan-&-balance-point"}
        excel={handleExportFistPage}
      />

      <form id="financForm">
        <div className="box_shadow container_grid_15">
          <p></p>
          {month_list_table.map((mon, i) => (
            <p className="p_finance_page" key={mon}>{mon}</p>
          ))}
          {rows.map(({ text, id }) => (
            <MonthTableRow key={id} text={text} id={id} />
          ))}
          <MonthTableBP
            key={"balance_point"}
            text={t("balance_point")}
            id={"balance_point"}
          />
        </div>
        <MonthSelect
          months={financeMonths}
          text={month_list}
          setIndex={setIndex}
          resetForm={"financForm"}
          setAddInputs={setAddInputs}
        />

        <p className="text_center month_title">{month_list[index]}</p>

        {/* <button
                    type='button'
                    onClick={(e) => buttonMonth(e)}
                >
                    {t('fixed_expenses_button')}</button> */}


        <h3 className="second_title">{t("Income")}</h3>
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Product_revenues")}
          state="product_revenues"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Service revenues")}
          state="service_revenues"
        />
        <TextButtonCell
          month={month}
          buttonText={t("Find_total_Income")}
          state="total_Income"
          text={+product_revenues + +service_revenues}
        />
        <h4 className="third_title">{t("text_message_1")}</h4>

        <h3 className="second_title">{t("Inventory_and_supplier_expenses")}</h3>

        <div>
          <TextInputCell
            month={month}
            currency={currency}
            page={"input_finance"}
            text={t("Inventory_cost")}
            state="inventory_cost"
          />
          <button
            type="button"
            className="plus-button"
            onClick={(e) => {
              e.preventDefault();
              setInventoryTotal((prev) => !prev);
            }}
          >
            +
          </button>
          {inventoryTotal && (
            <SupplierTable
              onSave={(total) => updateInventoryCost(total)}
              supplierHeader={t("Name_of_Supplier")}
              amountHeader={t("Amount_of_Expense")}
              saveButtonText={t("Save")}
            />
          )}
          <TextInputCell
            month={month}
            currency={currency}
            page={"input_finance"}
            text={t("Direct_Suplier_Expences")}
            state="suplier_expences"
          />
          <button
            type="button"
            className="plus-button"
            onClick={(e) => {
              e.preventDefault();
              setSupplierTotal((prev) => !prev);
            }}
          >
            +
          </button>
          {supplierTotal && (
            <SupplierTable
              onSave={(total) => updateSupplierExpenses(total)}
              supplierHeader={t("Name_of_Supplier")}
              amountHeader={t("Amount_of_Expense")}
              saveButtonText={t("Save")}
            />
          )}
        </div>

        <TextButtonCell
          month={month}
          buttonText={t("Find_Inventory_and_supplier_expenses")}
          state="inventory_and_supplier_expenses"
          text={y}
        />
        <br />
        <TextButtonCell
          textRed={gp_percentage >= 100}
          month={month}
          buttonText={t("Gross_profit_%")}
          state="gross_profit_prtcentage"
          text={gp_percentage}
        />
        <h4
          className={`third_title  status-message ${
            gp_percentage > 30 ? "red" : "green"
          }`}
        >
          {gp_percentage > 30 ? (
            <Fragment>
              <FontAwesomeIcon icon={faFlag} className="flag-icon flag-raise" />
              {/* <TextToSpeech text={t("gp_message_high")} /> */}
              {t("gp_message_high")}
            </Fragment>
          ) : (
            t("gp_message_low")
          )}
        </h4>

        <hr />

        <h3 className="second_title">{t("text_message_3")}</h3>
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Salary_expenses_(bruto)")}
          state="salary_expenses"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("National_Insurance")}
          state="insurance_expenses"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Expenses_for_employee_pension")}
          state="employee_expenses"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Compensation_expenses")}
          state="compensation_expenses"
        />
        <TextButtonCell
          month={month}
          buttonText={t("Find_salary_and_related_expenses")}
          state="salary_and_related_expenses"
          text={sc}
        />
        <br />
        <TextButtonCell
          textRed={sc_percentage >= 100}
          month={month}
          buttonText={t("%_from_income")}
          state="salary_and_related_expenses_prtcentage_from_income"
          text={sc_percentage}
        />
        <h4
          className={`third_title status-message ${
            sc_percentage > 30 ? "red" : "green"
          }`}
        >
          {sc_percentage > 30 ? (
            <Fragment>
              <FontAwesomeIcon icon={faFlag} className="flag-icon flag-raise" />
              {/* <TextToSpeech text={t("sc_message_high")} /> */}
              {t("sc_message_high")}
            </Fragment>
          ) : (
            t("sc_message_low")
          )}
        </h4>

        <hr />

        <h3 className="second_title">{t("Operational_and_managment")}</h3>
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Fuel/Cars_costs")}
          state="fuel_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Business_maintenance")}
          state="business_maintenance_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Offices")}
          state="offices_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Phone_and_communication")}
          state="phone_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Electricity")}
          state="electricity_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Parking")}
          state="parking_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Mail_for_marketing")}
          state="mail_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Gifts")}
          state="gifts_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Internet")}
          state="internet_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Computer_maintenance")}
          state="computer_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Business_Consulting_Offices_-_rent")}
          state="business_consulting_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Dues_and_membership_fees")}
          state="dues_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Work")}
          state="work_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Advertising_and_sales_promotion")}
          state="advertising_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Call_center_services")}
          state="call_center_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Cash_register_maintenance")}
          state="cash_register_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Equipment_maintenance")}
          state="equipment_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Credit_card_fees")}
          state="credit_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Property_tax_and_water")}
          state="property_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Fines")}
          state="fines_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Hosting")}
          state="hosting_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Work_clothes")}
          state="work_clothes_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Efreshments_for_the_office")}
          state="efreshments_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Bookkeeping")}
          state="bookkeeping_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Training")}
          state="training_expences"
        />
        {addInputs.map((item, i) => (
          <TextInputCallExtra
            month={month}
            currency={currency}
            page={"input_finance"}
            number={i}
            key={i}
            text={t("Extra_expense")}
            state={"extra_expense"}
          />
        ))}
        <button
          type="button"
          className="plus_button"
          onClick={(e) => {
            e.preventDefault();
            setAddInputs([...addInputs, 0]);
          }}
        >
          &#x002B;
        </button>

        <TextButtonCell
          month={month}
          buttonText={t("Find_operational_and_managment")}
          state="operational_and_managment"
          text={mc}
        />
        <br />
        <TextButtonCell
          textRed={mc_percentage >= 100}
          month={month}
          buttonText={t("%_from_income")}
          state="operational_and_managment_prtcentage_from_income"
          text={mc_percentage}
        />

        <h4
          className={`third_title status-message ${
            mc_percentage > 30 ? "red" : "green"
          }`}
        >
          {mc_percentage > 35 ? (
            <Fragment>
              <FontAwesomeIcon icon={faFlag} className="flag-icon flag-raise" />
              {/* <TextToSpeech text={t("mc_message_high")} /> */}
              {t("mc_message_high")}
            </Fragment>
          ) : mc_percentage > 30 ? (
            t("mc_message_warning")
          ) : (
            t("mc_message_low")
          )}
        </h4>
        <hr />

        <h3 className="second_title">{t("Financial expenses")}</h3>
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Bank_fees")}
          state="bank_fees_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Loans_return")}
          state="loans_expences"
        />
        <TextInputCell
          month={month}
          currency={currency}
          page={"input_finance"}
          text={t("Credit_fees_monthly")}
          state="credit_fees_expences"
        />
        <TextButtonCell
          month={month}
          buttonText={t("Find_financial_expenses")}
          text={fc}
          state="financial_expenses"
        />
        <br />

        <TextButtonCell
          textRed={fc_percentage >= 100}
          month={month}
          buttonText={t("%_from_income")}
          state="financial_expenses_prtcentage_from_income"
          text={fc_percentage}
        />
        <br />

        <TextButtonCell
          month={month}
          buttonText={t("balance_point")}
          state="balance_point"
          text={bp}
        />
        <h4 className="third_title">{t("text_message_2")}</h4>
        <hr />
        <ResetButton
          text={t("Save")}
          month={month}
          value={{ month: month, total, ...all_inputs, active: true }}
          setIndex={setIndex}
          setAddInputs={setAddInputs}
        />
      </form>
    </Fragment>
  );
}

export default FirstPage;



const defaultIncomeState = {
    month: "select month",
    current_account_status: 0,
    payments_expenses: 0,
    income_revenue: 0,
    action_type:0,
    how_was_made:0,
    date: "00-00-00",
    notes: "",
    detail_name:"",
    current_account_status_base: 0,
    currency: "faShekelSign",
    month_traker:
      {
        current_account_status: 0,
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select:0,
        fivePositionSelect:0,
        reason_select:0,
        date: "0000-00-00",
        notes: "",
        detail_name:"",
      }
    ,
    Month_action:[
      {
        current_account_status: 0,
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select:0,
        fivePositionSelect:0,
        reason_select:0,
        date: "0000-00-00",
        notes: "",
        detail_name:"",
      }
    ],
    ai: false,
    total_expense: 0,
    total_income: 0,
    account_frame: 0,
    Video_open: true,
    January:{
      month:"January",
      current_account_status_base: 0,
      account_frame: 0,
      total_expense: 0,
      total_income: 0,
    Month_action:[
      {
        current_account_status: 0,
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select:0,
        fivePositionSelect:0,
        reason_select:0,
        date: "0000-00-00",
        notes: "",
        detail_name:"",
      }
    ],
  },
    February:{
      month:"February",
      current_account_status_base: 0,
      account_frame: 0,
      total_expense: 0,
      total_income: 0,
    Month_action:[
      {
        current_account_status: 0,
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select:0,
        fivePositionSelect:0,
        reason_select:0,
        date: "0000-00-00",
        notes: "",
        detail_name:"",
      }
    ],
    },
    March:{
      month:"March",
      current_account_status_base: 0,
      account_frame: 0,
      total_expense: 0,
      total_income: 0,
    Month_action:[
      {
        current_account_status: 0,
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select:0,
        fivePositionSelect:0,
        reason_select:0,
        date: "0000-00-00",
        notes: "",
        detail_name:"",
      }
    ],
    },
    April:{
      month:"April",
      current_account_status_base: 0,
      account_frame: 0,
      total_expense: 0,
      total_income: 0,
    Month_action:[
      {
        current_account_status: 0,
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select:0,
        fivePositionSelect:0,
        reason_select:0,
        date: "0000-00-00",
        notes: "",
        detail_name:"",
      }
    ],
    },
    May:{
      month:"May",
      current_account_status_base: 0,
      account_frame: 0,
      total_expense: 0,
      total_income: 0,
    Month_action:[
      {
        current_account_status: 0,
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select:0,
        fivePositionSelect:0,
        reason_select:0,
        date: "0000-00-00",
        notes: "",
        detail_name:"",
      }
    ],
    },
    June:{
      month:"June",
      current_account_status_base: 0,
      account_frame: 0,
      total_expense: 0,
      total_income: 0,
    Month_action:[
      {
        current_account_status: 0,
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select:0,
        fivePositionSelect:0,
        reason_select:0,
        date: "0000-00-00",
        notes: "",
        detail_name:"",
      }
    ],
    },
    July:{
      month:"July",
      current_account_status_base: 0,
      account_frame: 0,
      total_expense: 0,
      total_income: 0,
    Month_action:[
      {
        current_account_status: 0,
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select:0,
        fivePositionSelect:0,
        reason_select:0,
        date: "0000-00-00",
        notes: "",
        detail_name:"",
      }
    ],
    },
    August:{
      month:"August",
      current_account_status_base: 0,
      account_frame: 0,
      total_expense: 0,
      total_income: 0,
    Month_action:[
      {
        current_account_status: 0,
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select:0,
        fivePositionSelect:0,
        reason_select:0,
        date: "0000-00-00",
        notes: "",
        detail_name:"",
      }
    ],
    },
    September:{
      month:"September",
      current_account_status_base: 0,
      account_frame: 0,
      total_expense: 0,
      total_income: 0,
    Month_action:[
      {
        current_account_status: 0,
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select:0,
        fivePositionSelect:0,
        reason_select:0,
        date: "0000-00-00",
        notes: "",
        detail_name:"",
      }
    ],
    },
    October:{
      month:"October",
      current_account_status_base: 0,
      account_frame: 0,
      total_expense: 0,
      total_income: 0,
    Month_action:[
      {
        current_account_status: 0,
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select:0,
        fivePositionSelect:0,
        reason_select:0,
        date: "0000-00-00",
        notes: "",
        detail_name:"",
      }
    ],
    },
    November:{
      month:"November",
      current_account_status_base: 0,
      account_frame: 0,
      total_expense: 0,
      total_income: 0,
    Month_action:[
      {
        current_account_status: 0,
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select:0,
        fivePositionSelect:0,
        reason_select:0,
        date: "0000-00-00",
        notes: "",
        detail_name:"",
      }
    ],
    },
    December:{
      month:"December",
      current_account_status_base: 0,
      account_frame: 0,
      total_expense: 0,
      total_income: 0,
    Month_action:[
      {
        current_account_status: 0,
        payments_expenses: 0,
        income_revenue: 0,
        income_expence_select:0,
        fivePositionSelect:0,
        reason_select:0,
        date: "0000-00-00",
        notes: "",
        detail_name:"",
      }
    ],
    },

  }

  // const names = Object.keys(defaultState);

  export const incomeReducer = (state = defaultIncomeState, action) => {
    switch (action.type) {
      case "month":
        return { ...state, month: action.payload };
      case "month_action":
        return { ...state,  Month_action: action.payload };
      case "January_income":
        return { ...state,  January: action.payload };
      case "February_income":
        return { ...state,  February: action.payload };
      case "March_income":
        return { ...state, March: action.payload };
      case "April_income":
        return { ...state, April: action.payload };
      case "May_income":
        return { ...state, May: action.payload };
      case "June_income":
        return { ...state, June: action.payload };
      case "August_income":
        return { ...state, August: action.payload };
      case "September_income":
        return { ...state, September: action.payload };
      case "October_income":
        return { ...state,  October: action.payload };
      case "November_income":
        return { ...state, November: action.payload };
      case "December_income":
        return { ...state,December: action.payload };
      case "payments_expenses":
        return { ...state, payments_expenses: action.payload };
      case "income_revenue":
        return { ...state, income_revenue: action.payload };
      case "current_account_status_base":
        return { ...state, current_account_status_base: action.payload, current_account_status: action.payload };
      case "current_account_status":
        return { ...state, current_account_status: action.payload };
      case "date":
        return { ...state, date: action.payload };
      case "notes":
        return { ...state, notes: action.payload };
      case "detail_name":
        return { ...state, detail_name: action.payload };
      case "total_expense":
        return { ...state, total_expense: action.payload };
      case "total_income":
        return { ...state, total_income: action.payload };
      case "account_frame":
        return { ...state, account_frame: action.payload };
      case "Video_open":
        return { ...state, Video_open: action.payload };
      case "currency":
        return { ...state, currency: action.payload };
      case "ai":
        return { ...state, ai: action.payload };

      default:
        return state;
    }
  };
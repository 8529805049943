const defaultBudgetState = {
  month: "select month",
  transaction_turnover_before_vat: 0,
  transaction_turnover_before: 0,
  inventory_and_suppliers_budget_amount: 0,
  inventory_and_suppliers_initialPercentage: 0.3,
  inventory_and_suppliers_actual_expense: 0,
  inventory_and_suppliers_budget_status: 0,
  employee_salaries_budget_amount: 0,
  employee_salaries_initialPercentage: 0.25,
  employee_salaries_actual_expense: 0,
  employee_salaries_budget_status: 0,
  operation_costs_budget_amount: 0,
  operation_costs_initialPercentage: 0.25,
  operation_costs_actual_expense: 0,
  operation_costs_budget_status: 0,
  repayments_of_loans_budget_amount: 0,
  repayments_of_loans_initialPercentage: 0.03,
  repayments_of_loans_actual_expense: 0,
  repayments_of_loans_budget_status: 0,
  total_budget_amount: 0,
  total_actual_expense: 0,
  total_budget_status: 0,
  January: {
    month: "January",
    // baseValue: 0,
    transaction_turnover_before_vat: 0,
    transaction_turnover_before: 0,
    inventory_and_suppliers_budget_amount: 0,
    inventory_and_suppliers_initialPercentage: 0.3,
    inventory_and_suppliers_actual_expense: 0,
    inventory_and_suppliers_budget_status: 0,
    employee_salaries_budget_amount: 0,
    employee_salaries_initialPercentage: 0.25,
    employee_salaries_actual_expense: 0,
    employee_salaries_budget_status: 0,
    operation_costs_budget_amount: 0,
    operation_costs_initialPercentage: 0.25,
    operation_costs_actual_expense: 0,
    operation_costs_budget_status: 0,
    repayments_of_loans_budget_amount: 0,
    repayments_of_loans_initialPercentage: 0.03,
    repayments_of_loans_actual_expense: 0,
    repayments_of_loans_budget_status: 0,
    total_budget_amount: 0,
    total_actual_expense: 0,
    total_budget_status: 0,
  },
  February: {
    month: "Fecruary",
    // baseValue: 0,
    transaction_turnover_before_vat: 0,
    transaction_turnover_before: 0,
    inventory_and_suppliers_budget_amount: 0,
    inventory_and_suppliers_initialPercentage: 0.3,
    inventory_and_suppliers_actual_expense: 0,
    inventory_and_suppliers_budget_status: 0,
    employee_salaries_budget_amount: 0,
    employee_salaries_initialPercentage: 0.25,
    employee_salaries_actual_expense: 0,
    employee_salaries_budget_status: 0,
    operation_costs_budget_amount: 0,
    operation_costs_initialPercentage: 0.25,
    operation_costs_actual_expense: 0,
    operation_costs_budget_status: 0,
    repayments_of_loans_budget_amount: 0,
    repayments_of_loans_initialPercentage: 0.03,
    repayments_of_loans_actual_expense: 0,
    repayments_of_loans_budget_status: 0,
    total_budget_amount: 0,
    total_actual_expense: 0,
    total_budget_status: 0,
  },
  March: {
    month: "Macch",
    // baseValue: 0,
    transaction_turnover_before_vat: 0,
    transaction_turnover_before: 0,
    inventory_and_suppliers_budget_amount: 0,
    inventory_and_suppliers_initialPercentage: 0.3,
    inventory_and_suppliers_actual_expense: 0,
    inventory_and_suppliers_budget_status: 0,
    employee_salaries_budget_amount: 0,
    employee_salaries_initialPercentage: 0.25,
    employee_salaries_actual_expense: 0,
    employee_salaries_budget_status: 0,
    operation_costs_budget_amount: 0,
    operation_costs_initialPercentage: 0.25,
    operation_costs_actual_expense: 0,
    operation_costs_budget_status: 0,
    repayments_of_loans_budget_amount: 0,
    repayments_of_loans_initialPercentage: 0.03,
    repayments_of_loans_actual_expense: 0,
    repayments_of_loans_budget_status: 0,
    total_budget_amount: 0,
    total_actual_expense: 0,
    total_budget_status: 0,
  },
  April: {
    month: "Apcil",
    // baseValue: 0,
    transaction_turnover_before_vat: 0,
    transaction_turnover_before: 0,
    inventory_and_suppliers_budget_amount: 0,
    inventory_and_suppliers_initialPercentage: 0.3,
    inventory_and_suppliers_actual_expense: 0,
    inventory_and_suppliers_budget_status: 0,
    employee_salaries_budget_amount: 0,
    employee_salaries_initialPercentage: 0.25,
    employee_salaries_actual_expense: 0,
    employee_salaries_budget_status: 0,
    operation_costs_budget_amount: 0,
    operation_costs_initialPercentage: 0.25,
    operation_costs_actual_expense: 0,
    operation_costs_budget_status: 0,
    repayments_of_loans_budget_amount: 0,
    repayments_of_loans_initialPercentage: 0.03,
    repayments_of_loans_actual_expense: 0,
    repayments_of_loans_budget_status: 0,
    total_budget_amount: 0,
    total_actual_expense: 0,
    total_budget_status: 0,
  },
  May: {
    month: "Mac",
    // baseValue: 0,
    transaction_turnover_before_vat: 0,
    transaction_turnover_before: 0,
    inventory_and_suppliers_budget_amount: 0,
    inventory_and_suppliers_initialPercentage: 0.3,
    inventory_and_suppliers_actual_expense: 0,
    inventory_and_suppliers_budget_status: 0,
    employee_salaries_budget_amount: 0,
    employee_salaries_initialPercentage: 0.25,
    employee_salaries_actual_expense: 0,
    employee_salaries_budget_status: 0,
    operation_costs_budget_amount: 0,
    operation_costs_initialPercentage: 0.25,
    operation_costs_actual_expense: 0,
    operation_costs_budget_status: 0,
    repayments_of_loans_budget_amount: 0,
    repayments_of_loans_initialPercentage: 0.03,
    repayments_of_loans_actual_expense: 0,
    repayments_of_loans_budget_status: 0,
    total_budget_amount: 0,
    total_actual_expense: 0,
    total_budget_status: 0,
  },
  June: {
    month: "Juce",
    // baseValue: 0,
    transaction_turnover_before_vat: 0,
    transaction_turnover_before: 0,
    inventory_and_suppliers_budget_amount: 0,
    inventory_and_suppliers_initialPercentage: 0.3,
    inventory_and_suppliers_actual_expense: 0,
    inventory_and_suppliers_budget_status: 0,
    employee_salaries_budget_amount: 0,
    employee_salaries_initialPercentage: 0.25,
    employee_salaries_actual_expense: 0,
    employee_salaries_budget_status: 0,
    operation_costs_budget_amount: 0,
    operation_costs_initialPercentage: 0.25,
    operation_costs_actual_expense: 0,
    operation_costs_budget_status: 0,
    repayments_of_loans_budget_amount: 0,
    repayments_of_loans_initialPercentage: 0.03,
    repayments_of_loans_actual_expense: 0,
    repayments_of_loans_budget_status: 0,
    total_budget_amount: 0,
    total_actual_expense: 0,
    total_budget_status: 0,
  },
  July: {
    month: "Jucy",
    // baseValue: 0,
    transaction_turnover_before_vat: 0,
    transaction_turnover_before: 0,
    inventory_and_suppliers_budget_amount: 0,
    inventory_and_suppliers_initialPercentage: 0.3,
    inventory_and_suppliers_actual_expense: 0,
    inventory_and_suppliers_budget_status: 0,
    employee_salaries_budget_amount: 0,
    employee_salaries_initialPercentage: 0.25,
    employee_salaries_actual_expense: 0,
    employee_salaries_budget_status: 0,
    operation_costs_budget_amount: 0,
    operation_costs_initialPercentage: 0.25,
    operation_costs_actual_expense: 0,
    operation_costs_budget_status: 0,
    repayments_of_loans_budget_amount: 0,
    repayments_of_loans_initialPercentage: 0.03,
    repayments_of_loans_actual_expense: 0,
    repayments_of_loans_budget_status: 0,
    total_budget_amount: 0,
    total_actual_expense: 0,
    total_budget_status: 0,
  },
  August: {
    month: "Aucust",
    // baseValue: 0,
    transaction_turnover_before_vat: 0,
    transaction_turnover_before: 0,
    inventory_and_suppliers_budget_amount: 0,
    inventory_and_suppliers_initialPercentage: 0.3,
    inventory_and_suppliers_actual_expense: 0,
    inventory_and_suppliers_budget_status: 0,
    employee_salaries_budget_amount: 0,
    employee_salaries_initialPercentage: 0.25,
    employee_salaries_actual_expense: 0,
    employee_salaries_budget_status: 0,
    operation_costs_budget_amount: 0,
    operation_costs_initialPercentage: 0.25,
    operation_costs_actual_expense: 0,
    operation_costs_budget_status: 0,
    repayments_of_loans_budget_amount: 0,
    repayments_of_loans_initialPercentage: 0.03,
    repayments_of_loans_actual_expense: 0,
    repayments_of_loans_budget_status: 0,
    total_budget_amount: 0,
    total_actual_expense: 0,
    total_budget_status: 0,
  },
  September: {
    month: "Sectember",
    // baseValue: 0,
    transaction_turnover_before_vat: 0,
    transaction_turnover_before: 0,
    inventory_and_suppliers_budget_amount: 0,
    inventory_and_suppliers_initialPercentage: 0.3,
    inventory_and_suppliers_actual_expense: 0,
    inventory_and_suppliers_budget_status: 0,
    employee_salaries_budget_amount: 0,
    employee_salaries_initialPercentage: 0.25,
    employee_salaries_actual_expense: 0,
    employee_salaries_budget_status: 0,
    operation_costs_budget_amount: 0,
    operation_costs_initialPercentage: 0.25,
    operation_costs_actual_expense: 0,
    operation_costs_budget_status: 0,
    repayments_of_loans_budget_amount: 0,
    repayments_of_loans_initialPercentage: 0.03,
    repayments_of_loans_actual_expense: 0,
    repayments_of_loans_budget_status: 0,
    total_budget_amount: 0,
    total_actual_expense: 0,
    total_budget_status: 0,
  },
  October: {
    month: "Occober",
    // baseValue: 0,
    transaction_turnover_before_vat: 0,
    transaction_turnover_before: 0,
    inventory_and_suppliers_budget_amount: 0,
    inventory_and_suppliers_initialPercentage: 0.3,
    inventory_and_suppliers_actual_expense: 0,
    inventory_and_suppliers_budget_status: 0,
    employee_salaries_budget_amount: 0,
    employee_salaries_initialPercentage: 0.25,
    employee_salaries_actual_expense: 0,
    employee_salaries_budget_status: 0,
    operation_costs_budget_amount: 0,
    operation_costs_initialPercentage: 0.25,
    operation_costs_actual_expense: 0,
    operation_costs_budget_status: 0,
    repayments_of_loans_budget_amount: 0,
    repayments_of_loans_initialPercentage: 0.03,
    repayments_of_loans_actual_expense: 0,
    repayments_of_loans_budget_status: 0,
    total_budget_amount: 0,
    total_actual_expense: 0,
    total_budget_status: 0,
  },
  November: {
    month: "Nucember",
    // baseValue: 0,
    transaction_turnover_before_vat: 0,
    transaction_turnover_before: 0,
    inventory_and_suppliers_budget_amount: 0,
    inventory_and_suppliers_initialPercentage: 0.3,
    inventory_and_suppliers_actual_expense: 0,
    inventory_and_suppliers_budget_status: 0,
    employee_salaries_budget_amount: 0,
    employee_salaries_initialPercentage: 0.25,
    employee_salaries_actual_expense: 0,
    employee_salaries_budget_status: 0,
    operation_costs_budget_amount: 0,
    operation_costs_initialPercentage: 0.25,
    operation_costs_actual_expense: 0,
    operation_costs_budget_status: 0,
    repayments_of_loans_budget_amount: 0,
    repayments_of_loans_initialPercentage: 0.03,
    repayments_of_loans_actual_expense: 0,
    repayments_of_loans_budget_status: 0,
    total_budget_amount: 0,
    total_actual_expense: 0,
    total_budget_status: 0,
  },
  December: {
    month: "December",
    // baseValue: 0,
    transaction_turnover_before_vat: 0,
    transaction_turnover_before: 0,
    inventory_and_suppliers_budget_amount: 0,
    inventory_and_suppliers_initialPercentage: 0.3,
    inventory_and_suppliers_actual_expense: 0,
    inventory_and_suppliers_budget_status: 0,
    employee_salaries_budget_amount: 0,
    employee_salaries_initialPercentage: 0.25,
    employee_salaries_actual_expense: 0,
    employee_salaries_budget_status: 0,
    operation_costs_budget_amount: 0,
    operation_costs_initialPercentage: 0.25,
    operation_costs_actual_expense: 0,
    operation_costs_budget_status: 0,
    repayments_of_loans_budget_amount: 0,
    repayments_of_loans_initialPercentage: 0.03,
    repayments_of_loans_actual_expense: 0,
    repayments_of_loans_budget_status: 0,
    total_budget_amount: 0,
    total_actual_expense: 0,
    total_budget_status: 0,
  },
};

export const budgetReducer = (state = defaultBudgetState, action) => {
  switch (action.type) {
    case "reset_b":
      return {
        month: "select month",
        operation_costs_initialPercentage: 0.25,
        employee_salaries_initialPercentage: 0.25,
        repayments_of_loans_initialPercentage: 0.03,
        inventory_and_suppliers_initialPercentage: 0.3,
        transaction_turnover_before_vat: 0,
        transaction_turnover_before: 0,
        inventory_and_suppliers_actual_expense: 0,
        employee_salaries_actual_expense: 0,
        operation_costs_actual_expense: 0,
        repayments_of_loans_actual_expense: 0,
        inventory_and_suppliers_budget_amount: 0,
        employee_salaries_budget_amount: 0,
        operation_costs_budget_amount: 0,
        repayments_of_loans_budget_amount: 0,
        totalBudgetAmount: 0,
        totalActualExpense: 0,
        January: state.January,
        February: state.February,
        March: state.March,
        April: state.April,
        May: state.May,
        June: state.June,
        July: state.July,
        August: state.August,
        September: state.September,
        October: state.October,
        November: state.November,
        December: state.December,
      };
    case "month":
      return { ...state, month: action.payload };
    case "January_budget":
      return { ...state, January: action.payload };
    case "February_budget":
      return { ...state, February: action.payload };
    case "March_budget":
      return { ...state, March: action.payload };
    case "April_budget":
      return { ...state, April: action.payload };
    case "May_budget":
      return { ...state, May: action.payload };
    case "June_budget":
      return { ...state, June: action.payload };
    case "July_budget":
      return { ...state, July: action.payload };
    case "August_budget":
      return { ...state, August: action.payload };
    case "September_budget":
      return { ...state, September: action.payload };
    case "October_budget":
      return { ...state, October: action.payload };
    case "November_budget":
      return { ...state, November: action.payload };
    case "December_budget":
      return { ...state, December: action.payload };
    case "SET_TURNOVER":
      return { ...state, transaction_turnover_before_vat: action.payload };
    case "inventory_and_suppliers_actual_expense":
    case "employee_salaries_actual_expense":
    case "operation_costs_actual_expense":
    case "repayments_of_loans_actual_expense":
    case "inventory_and_suppliers_budget_amount":
    case "employee_salaries_budget_amount":
    case "operation_costs_budget_amount":
    case "repayments_of_loans_budget_amount":
    case "inventory_and_suppliers_budget_status":
    case "employee_salaries_budget_status":
    case "operation_costs_budget_status":
    case "repayments_of_loans_budget_status":
    case "inventory_and_suppliers_initialPercentage":
    case "employee_salaries_initialPercentage":
    case "operation_costs_initialPercentage":
    case "repayments_of_loans_initialPercentage":
    case "transaction_turnover_before_vat":
      // case "total_budget_status":
      return { ...state, [action.type]: action.payload };
    case "UPDATE_TOTAL_BUDGET":
      return {
        ...state,
        total_budget_amount:
          state.inventory_and_suppliers_budget_amount +
          state.employee_salaries_budget_amount +
          state.operation_costs_budget_amount +
          state.repayments_of_loans_budget_amount,
      };
    case "UPDATE_TOTAL_EXPENSE":
      return {
        ...state,
        total_actual_expense:
          state.inventory_and_suppliers_actual_expense +
          state.employee_salaries_actual_expense +
          state.operation_costs_actual_expense +
          state.repayments_of_loans_actual_expense,
      };
    case "UPDATE_TOTAL_STATUS":
      return {
        ...state,
        total_budget_status:
          state.total_budget_amount - state.total_actual_expense,
      };

    default:
      return state;
  }
};

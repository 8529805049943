import { useDispatch, useSelector} from 'react-redux';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useEffect } from 'react';

function TextCell(props) {
    const { text,state ,page , currency , month} = props;
    const dispatch = useDispatch();
    const addValue = (e)=>{dispatch({type:state,payload:e.target.value})};
    const input_value = useSelector(state => state.finance[month]);
    useEffect(()=>{
        if(input_value){dispatch({type:state,payload:input_value[state]})};
    },[input_value, state, dispatch]);


    return (
            <div className='container_grid_three '>
                <div className='text_cell'>{text}</div>
                <div className='text_cell'>
                <FontAwesomeIcon icon={currency}/>
                </div>

                <input
                id={state}
                className={page? page: 'Input'}
                defaultValue={input_value? ((input_value[state] > 0) ? input_value[state]: "") : ""}
                dir="rtl"
                type="number"
                min="0"
                onBlur={e=>addValue(e)}/>
            </div>

    )
}

export default TextCell;


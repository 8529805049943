import React from "react";
import "./Artical.css";
import { useTranslation } from 'react-i18next';
function SalesObjections() {
  const { t } = useTranslation('SalesTraining');
  return (
    <div className="paragraph">
      <p>
        {t('selse_objections_text_1')}
        {t('selse_objections_text_2')}
        <br />

      </p>
      <p>
        {t('selse_objections_first_option_h_1')}
        <br />
        <br />
        {t('selse_objections_first_option_text_1')}
        <br />
        <br />
        {t('selse_objections_first_option_text_2')}

      </p>
      <p>
      {t('selse_objections_second_option_h_1')}
        <br />
        <br />
      {t('selse_objections_second_option_text_1')}
        <br />
        <br />
      {t('selse_objections_second_option_text_2')}
        <br />

      </p>
      <p>
      {t('selse_objections_third_option_h_1')}
        <br/>
        <br/>
      {t('selse_objections_third_option_text_1')}
        <br/>
      {t('selse_objections_third_option_text_2')}
        <br/>

      </p>
    </div>
  );
}

export default SalesObjections;

import "./Home.css";
import React, { Fragment } from "react";
import HomeVideoModal from "../../components/Modals/HomeVideoModal/HomeVideoModal";
import { useTranslation } from "react-i18next";

function Home() {
  const { t} = useTranslation("Home"); // ensure the namespace is correct

  return (
    <Fragment>
      <div className="HomePage">


        <div className="video-container_p">
          <HomeVideoModal videoUrl={t('video_url')} />
        </div>
{/*
        <section dir={i18n.language === "he" ? "rtl" : "ltr"}>
          <p>{t("creating_financial_plan")}</p>
          <p>{t("financial_plan_based_on_reports")}</p>
          <p>{t("plan_divided_into_months")}</p>
          <p>{t("top_of_plan_revenues")}</p>
          <p>{t("profit_center_revenues")}</p>
          <p>{t("below_income_expenses")}</p>
          <p>{t("both_raw_materials_and_suppliers")}</p>
          <p>{t("direct_expenses_recorded")}</p>
          <p>{t("actual_costs_summary")}</p>
        </section> */}
      </div>
    </Fragment>
  );
}

export default Home;

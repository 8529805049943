import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { financeReducer } from "./financeReducer";
import { budgetReducer } from "./budgetReducer";
import { incomeReducer } from "./incomeReducer";
import {salesReducer } from "./salesReducer"
import { appReducer } from "./appReducer";


 const rootReducer = combineReducers({
  finance: financeReducer,
  budget: budgetReducer,
  income: incomeReducer,
  sales: salesReducer,
  app: appReducer
});

export const store = configureStore({reducer: rootReducer});
import React from "react";
import "./VideoNewModal.css";
import ReactPlayer from "react-player";
// import VideoSizeButton from "../../Buttons/VideoSizeButten/VideoSizeButten.js";
// import ReloadButton from "../Buttons/ReloedButton/ReloedButton.js";
import { useTranslation } from "react-i18next";
// import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";

function VideoNewModal({ videoUrl }) {
  // const [size, setSize] = useState(55);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const Video_open = useSelector((state) => state.income.Video_open);


  return (
    <div className={`player-wrapper ${i18n.language === "he" ? "rtl" : "ltr"}`}>
      {Video_open && (
        <div>
          <div className="video-container">
            <ReactPlayer
              className="react-player"
              url={videoUrl}
              // width={`${size}vh`}
              // height={"auto"}
              width={`100%`}
              height={"100%"}
              controls={true}
              onEnded={() => {
                dispatch({ type: "Video_open", payload: false });
              }}
            />
            <div className="reload-button-container">
              <div className="buttons-container">
                {/* <ReloadButton /> */}
                <button
                  className="exsit-button"
                  onClick={() => {
                    dispatch({ type: "Video_open", payload: false });
                  }}
                >
                  X
                  {/* <FontAwesomeIcon icon={faCircleXmark} /> */}
                </button>
              </div>
            </div>
          </div>
          {/* <VideoSizeButton size={size} setSize={setSize} /> */}
        </div>
      )}
    </div>
  );
}

export default VideoNewModal;

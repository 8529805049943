import React, {Fragment, useState} from 'react'
import { useTranslation } from "react-i18next";
import "./CreatingWorkflow.css"
import ActionBox from '../../components/ActionBox/ActionBox';
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";

function CreatingWorkflow() {
    const { t } = useTranslation("Workflow");
    // const [actions, setActions] = useState([0,0,0,0,0,0]);
    const [actions] = useState([0,0,0,0,0,0]);
  return (
    <Fragment>
    <div>CreatingWorkflow</div>
    <br/>

    <div className="video-container_p">
        <VideoNewModal videoUrl={t("video_url")} />
      </div>

    <p>{t('text1')}</p>
    <p>{t('text2')}</p>
    <p>{t('text3')}</p>
    <p>{t('text4')}</p>
    <p>{t('text5')}</p>
    <div className='workflow_box'>

    {
        actions.map((action, i )=> (
            <ActionBox number={1+i}/>
        ))
    }
    </div>
    </Fragment>
  )
}

export default CreatingWorkflow;
import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";
import { stages } from "../../../src/data/SalesCustomerStages";
import "./SalesCustomer.css";

export default function SalesCustomer() {
  const [currentStage, setCurrentStage] = useState(1);
  const { t } = useTranslation("Sales");

  const handleStageChange = (stageId) => {
    setCurrentStage(stageId);
  };
  return (
    <Fragment>
      <div className="sales_customer">
        <div className="header-title">{t("Stage_2_Title")}</div>
        
      <div className="video-container_p">
        <VideoNewModal videoUrl={t("video_url2")} />
      </div>

        <div className="progress_container">
          {stages.map((stage, index) => (
            <div key={index} className="progress_step">
              <div
                className={`circle ${currentStage > stage.id ? "completed" : ""}
               ${currentStage === stage.id && "current"}`}
                onClick={() => handleStageChange(stage.id)}
              >
                {stage.id}
              </div>
              {index < stages.length - 1 && (
                <div
                  className={`line ${currentStage > stage.id ? "filled" : ""}`}
                ></div>
              )}
            </div>
          ))}
        </div>
        <div className="stage_content">
          <h2 className="text_center">{t(stages[currentStage - 1]?.text)}</h2>
          <p className="text_center">
            {t(stages[currentStage - 1]?.description)}
          </p>
        </div>
      </div>
    </Fragment>
  );
}

import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import "./i18n";
import { store } from "./Reducers/index.js";
import { GoogleOAuthProvider } from "@react-oauth/google";

import Login from "./Pages/Login/Login.js";
import Layout from "./layouts/Layout.js";
import Loader from "./components/Loader/Loader.js";
// import SignUp from "./Pages/SignUp/SignUp.js";

import Home from "./Pages/Home/Home";
import FirstPage from "./Pages/FirstPage/FirstPage";
import Budget from "./Pages/Budget/Budget.js";
import Income from "./Pages/Income/Income.js";

import Sales from "./Pages/Sales/Sales.js";
import AdvertisingChannels from "./Pages/AdvertisingChannels/AdvertisingChannels.js";
import SalesCustomer from "./Pages/SalesCustomer/SalesCustomer.js";
import SalesTraining from "./Pages/SalesTraining/SalesTraining.js";
import SalesManagment from "./Pages/SalesManagment/SalesManagment.js";

import MarketingControl from "./Pages/MarketingControl/MarketingControl.js";
import WorkPlan from "./Pages/WorkPlan/WorkPlan.js";
import CustomerExperience from "./Pages/CustomerExperience/CustomerExperience.js";
import CreatingWorkflow from "./Pages/CreatingWorkflow/CreatingWorkflow.js";
import DefineRules from "./Pages/DefineRules/DefineRules.js";

function App() {
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId="386932037035-k8v833noqjk7m4***********.apps.googleusercontent.com">
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <Layout>
              <Routes>
                <Route path="/" element={<Navigate to="/login" replace />} />
                {/* <Route path="/signup" element={<SignUp />} /> */}
                <Route path="/login" element={<Login />} />
                <Route path="/home" element={<Home />} />
                {/* finance */}
                <Route path="/first" element={<FirstPage />} />
                <Route path="/second" element={<Budget />} />
                <Route path="/third" element={<Income />} />
                {/* sales */}
                <Route path="/sales" element={<Sales />} />
                <Route path="/sales-customer" element={<SalesCustomer />} />
                <Route path="/sales-training" element={<SalesTraining />} />
                <Route path="/advertising-channels"element={<AdvertisingChannels />}/>
                <Route path="/sales-managment" element={<SalesManagment />} />
                <Route path="/sales-effectiveness" element={<MarketingControl />}/>
                {/* managment & operations */}
                <Route path="/creating-workflow" element={<CreatingWorkflow />}/>
                <Route path="/confined-work-plan" element={<WorkPlan />}/>
                <Route path="/customer-experience" element={<CustomerExperience />}/>
                <Route path="/define-rules" element={<DefineRules />} />
              </Routes>
            </Layout>
          </BrowserRouter>
        </Suspense>
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;

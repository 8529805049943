import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Income.css";
import { months, formatNumber } from "../../helpers/helpers.js";
// import NineTableRow from "../../components/Tables/NineTableRow/NineTableRow.js";
// import MonthSelect from "../../components/Selectors/MonthSelect/MonthSelect.js";
import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal.js";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFlag, faShekelSign } from "@fortawesome/free-solid-svg-icons";
import { faFlag} from "@fortawesome/free-solid-svg-icons";
import FunctionalInput from "../../components/Inputs/FunctionalInput/FunctionalInput.js";
// import {handleExport} from "../../Excel/incomePageFunction.js"
import { handleExport } from '../../Excel/incomePageFunction.js'
// import { handleExport } from '../../Excel/generateBudgetData.js'
import ExcelButton from "../../components/Buttons/ExcelButton/ExcelButton.js";
// import TextToSpeech from "../../components/TextToSpeech/TextToSpeech";
import IncomeTableInput from "../../components/Tables/IncomeTableInput/IncomeTableInput.js";
import MonthSelectIncome from "../../components/Selectors/MonthSelectIncome/MonthSelectIncome.js";

// import { IncomePostReq } from "./IncomeReq.js";

function Income() {
// IncomePostReq()
    useEffect(() => {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = 'Are you sure you want to leave? Please save data !';
      };

      window.addEventListener('beforeunload', handleBeforeUnload);  


      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);  

      };
    }, []);
  const dispatch = useDispatch();
  const { t } = useTranslation("Incom");
  const month = useSelector((state) => state.income.month);

  const month_list = t("month_text").split(",");

  // const payments_expenses = useSelector((state) => state.income.payments_expenses);
  const current_account_status_base = useSelector((state) => state.income.current_account_status_base);
  const account_frame = useSelector((state) => state.income.account_frame);
  // const current_account_status = useSelector((state) => state.income.current_account_status);
  const January = useSelector((state) => state.income.January);
  const February = useSelector((state) => state.income.February);
  const March = useSelector((state) => state.income.March);
  const May = useSelector((state) => state.income.May);
  const April = useSelector((state) => state.income.April);
  const June = useSelector((state) => state.income.June);
  const July = useSelector((state) => state.income.July);
  const August = useSelector((state) => state.income.August);
  const September = useSelector((state) => state.income.September);
  const October = useSelector((state) => state.income.October);
  const November = useSelector((state) => state.income.November);
  const December = useSelector((state) => state.income.December);
  const current_month = useSelector((state) => state.income[month]);
  const total_expense = useSelector((state) => state.income.total_expense);
  const total_income = useSelector((state) => state.income.total_income);
  const month_traker = useSelector((state) => state.income.month_traker);
  const Month_action = useSelector((state) => state.income.Month_action);
  // const [actionList, setActionList] = useState(Month_action)
  const [addDate, setAddDate] = useState("0000-00-00");
  const getDate = (array)=>{
    let theDate = "0000-00-00"
      for(let i = 0; i < array.length; i++){
        if(Number(array[i].payments_expenses) > 0 && array[i].date > theDate){
          theDate = array[i].date
        }
      }
      return theDate
      }
  useEffect(()=>{
    const data = getDate(Month_action)
    setAddDate(data);
    // setActionList(Month_action)
  },[Month_action]);
  const saveMonth = (e)=>{
    e.preventDefault();
    dispatch({type: month + "_income", payload: {...current_month,total_expense: total_expense,total_income: total_income,Month_action}});
    dispatch({type:"month_action",payload:[month_traker]});
    dispatch({ type: "total_income", payload: 0 });
    dispatch({ type: "total_expense", payload: 0 });
    dispatch({type:"current_account_status_base",payload:0});
    dispatch({type:"account_frame",payload:0});
    dispatch({type:"month",payload:"select month" });
    document.getElementById("incomeForm").reset();
  }

  // const changeTotalExpenseValue = (e) => {
  //   e.preventDefault()
  //   dispatch({ type: "total_expense", payload: e.target.value })
  //   dispatch({ type: month + "_income", payload: {...current_month, [e.target.name]:e.target.value}})
  //   return;
  // };

  // const changeTotalIncomeValue = (e) => {
  //   e.preventDefault()
  //   dispatch({ type: "total_income", payload: e.target.value })
  //   dispatch({ type: month + "_income", payload: {...current_month[month], [e.target.name]:e.target.value}})
  //   return;
  // };
  // const addDate = Month_action.map((action)=>{
  //   if(action.payments_expenses > 0) return action.date
  // });
  const [index, setIndex] = useState([0]);
  // const [rowCount, setRowCount] = useState(6);
  // const currency = useSelector((state) => state.income.currency);

  const addRow = (e) => {
    e.preventDefault();
    dispatch({type: "month_action", payload:[...Month_action, {...month_traker}]});
    // setRowCount((prevCount) => prevCount + 1)
    // setRowCount((prevCount) => prevCount + 1)

    return;
  };
const sortMonth = (e)=>{
  e.preventDefault()
  const new_array = Array.from(Month_action);
  new_array.sort((b,a) => new Date(b.date) - new Date(a.date));
  dispatch({type: "month_action", payload:[...new_array]});
  document.getElementById("incomeForm").reset();

}
  return (
    <Fragment>
        <div className="container" style={{ justifyContent: "start" }}>
          <div className="header_title3">
            {/* {t("cash_flow_management")} */}
            </div>
        </div>
      <div className='video-container_p'>
      <VideoNewModal videoUrl={t('video_url')} />
      </div>

      <form id="incomeForm">
        <MonthSelectIncome
          text={month_list}
          setIndex={setIndex}
          resetForm={"incomeForm"}
          months={months}
          page={"_income"}
        />
        <p className="text_center month_title">{month_list[index]}</p>

        <div className="main-page-content">
          <div className="inputs-container-xl-container">
            <div className="inputs-container">
              <FunctionalInput
                text={"Current_account_status"}
                state={"current_account_status_base"}
                month={month}
                page={"_income"}
                Month_action={Month_action}
              />

              <FunctionalInput
                text={"Account_Frame"}
                state={"account_frame"}
                month={month}
                page={"_income"}
              />
            </div>
            {/* <div className="xl-container"> */}
            <ExcelButton
            name={"income"}
            data={[January,February,March, April,May,June,July,August,September,October,November,December]}
            excel={handleExport}
            />
            {/* </div> */}
          </div>


          <div className="table">
            <div className="container_grid_nine">
              <div className="cell_date"><p onClick={(e)=> sortMonth(e)}>{t("Date_of_Income_Expense")}</p></div>
              <div><p>{t("Type_of_Income_Expense")}</p></div>
              <div><p>{t("What_is_the_expense_income_for")}</p></div>
              <div><p>{t("Detail_of_expenditure_customer_name")}</p></div>
              <div><p>{t("How_the_expense_income_was_made")}</p></div>
              <div><p>{t("Payments_Expenses")}</p></div>
              <div><p>{t("Income")}</p></div>
              <div><p>{t("Current_account_status_table")}</p></div>
              <div><p>{t("Notes_Remarks_for_control_and_analysis")}</p></div>
            </div>

            {Month_action.map((action, i) => (
              <IncomeTableInput month={month} key={i} action={action} input_number={i} page={"_income"}/>
            ))}

            <div className="container_grid_nine">
              <div />
              <div />
              <div />
              <div />
              <div />
              <div>
                <input
                  name="total_expense"
                  className="total-expense"
                  placeholder="0"
                  dir="rtl"
                  disabled
                  // type="number"
                  // onChange={changeTotalExpenseValue}
                  value={formatNumber(total_expense)}
                />
              </div>
              <div>
                <input
                  name="total_income"
                  className="total-income"
                  placeholder="0"
                  dir="rtl"
                  disabled
                  // type="number"
                  // onChange={changeTotalIncomeValue}
                  value={formatNumber(total_income)}
                />
              </div>
              <div />
              <div />
            </div>

            <div className="plus-button-container">
              <button
              type="button"
                className="plus-button"
                onClick={(e) => {addRow(e)}}
              >
                &#x002B;
              </button>
            </div>
          </div>
          {/* <TextToSpeech text={""} /> */}
          <div className="bottom-details">
            {+total_expense  > +total_income
              ? ( <Fragment>

                  {/* <TextToSpeech text={t("Total_expenses_are_greater_than_total_income")} /> */}
                <p className="red_title">
                  <FontAwesomeIcon
                    icon={faFlag}
                    className="flag-icon flag-raise"
                    />
                  {t("Total_expenses_are_greater_than_total_income")}
                  </p>
                    </Fragment>)
              : ""}
              {+current_account_status_base + +total_income + +account_frame + -total_expense < 0 ? (

                <Fragment>
                  <br />
                    {/* <TextToSpeech text={`${t("On")} ${addDate} ${t("date_expense_greater_than_current_amount")}`} /> */}
                  <p className="red_title">

                  <FontAwesomeIcon
                    icon={faFlag}
                    className="flag-icon flag-raise"
                    />
                  {`${t("On")} ${addDate} ${t("date_expense_greater_than_current_amount")}`}
                    </p>
                </Fragment>)

            :""}

            <p> {t("Recommendations_for_action")}</p>
            <p>{t("Lorem_ipsum")}</p>
            <p>{t("Lorem_ipsum")}</p>
            <p>{t("Lorem_ipsum")}</p>
            <div className="button_container">
              <button type="button" onClick={(e)=> saveMonth(e)} className="save_button">{t("Save")}</button>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
}

export default Income;

import React from 'react'
import { useTranslation } from 'react-i18next';
import "./fivePositionSelect.css";


function TwelvePositionSelect(props) {
  const { t } = useTranslation("Incom");
  // const [selectedOption, setSelectedOption] = useState("1");
  const { id, handleSelectChange, fpValue } = props;
  return (
    <select
    id={id}
      value={fpValue}
      name={'fivePositionSelect'}
      onChange={(e) => {
        handleSelectChange(e)
        // setSelectedOption(e.target.value)
      }}
      className='fivePositionSelect'>
      <option value="1">{t("Check")}</option>
      <option value="2">{t("Credit_card")}</option>
      <option value="3">{t("Cash")}</option>
      <option value="4">{t("Bank_Transfer")}</option>
      <option value="5">{t("Transfer_via_app")}</option>
    </select>
  )
}

export default TwelvePositionSelect;

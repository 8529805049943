export const stages = [
  {
    id: 1,
    text: "Awareness_Visibility_Text",
    description: "Awareness_Visibility_Desc",
  },
  {
    id: 2,
    text: "Market_Authority_Text",
    description: "Market_Authority_Desc",
  },
  {
    id: 3,
    text: "Emotional_Connection_Text",
    description: "Emotional_Connection_Desc",
  },
  {
    id: 4,
    text: "Call_to_Action_Text",
    description: "Call_to_Action_Desc",
  },
  {
    id: 5,
    text: "Undecided_Campaign_Text",
    description: "Undecided_Campaign_Desc",
  },
];

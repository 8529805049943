// import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useState } from "react";
import {formatNumber} from "../../../helpers/helpers"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./TurnoverInput.css";

export default function TurnoverInput({ text, state, month, page, currency, thisMonth }) {
  const { t } = useTranslation("Budget");
  // const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const current_month = useSelector((state) => state.budget[month]);
  // const turnover = useSelector(
  //   (state) => state.budget.transaction_turnover_before_vat
  // );
  // const toggleEditing = (e) => {
  //   e.preventDefault();
  //   setIsEditing(!isEditing);
  //   return;
  // };
  const handleTurnoverChange = (e) => {
    const { name, value } = e.target;
    const newValue = Number(value.replace(",",""));
    dispatch({ type: state, payload: newValue });
    dispatch({ type: "transaction_turnover_before_vat", payload: newValue });
    dispatch({
      type: month + page,
      payload: { ...current_month, [name]: newValue , transaction_turnover_before_vat: newValue},
    });
    return;
  };

  return (
    <div className="top-container">
      <div className="current-status">
        <h3 className="transaction_turnover_title m_10">{t(text)}</h3>
        <FontAwesomeIcon icon={currency} />
        <input
          className={`current-status-input ${
            // isEditing
            //   ? "current-status-input-abled"
               "current-status-input-disabled"
          }`}
          name="baseValue"
          defaultValue={current_month ? formatNumber(current_month["transaction_turnover_before_vat"]) : 0}
          onChange={handleTurnoverChange}
          disabled
          dir="rtl"
          // type="number"
          placeholder={0}
        />
        {/* <button
          type="button"
          className="disabled-button"
          onClick={toggleEditing}
        >
          <FontAwesomeIcon icon={faPencil} />
        </button> */}
      </div>
    </div>
  );
}

import React, { useState, Fragment } from 'react'
import { useTranslation } from "react-i18next";
import "./WorkPlan.css"
import GoalsSelect from '../../components/Selectors/GoalsSelect/GoalsSelect';
import ObjectivSelect from '../../components/Selectors/TargetSelect/ObjectivSelect';
import ActionSelect from '../../components/Selectors/ActionSelect/ActionSelect';
import IndicatorsInput from '../../components/Selectors/IndicatorsInput/IndicatorsInput';
import Pointer from '../../components/Pointer/Pointer';
function WorkPlan() {
    const { t } = useTranslation("WorkPlan");
    const [goals, setGoals] = useState([]);
    const [indicators, setIndicators] = useState([]);
    const [objectives, setobjectives] = useState([]);
    const [actions, setActions] = useState([]);
    console.log(indicators);
    const addIndicators = () => {
        if(indicators.length < objectives.length) setIndicators([...indicators, { name: "", objectives: [...objectives], actions: [...actions] }]);
    }
    const objectivesUpdate = () => {
        setobjectives([...objectives, { name: "", connection: false }]);
        const goalsNewList = Array.from(goals);
        goalsNewList.map((goal) => {
            return goal.objectives = [...goal.objectives, { name: "", connection: false }]
        });
        setGoals([...goalsNewList]);
        const indicatorsNewList = Array.from(indicators);
        indicatorsNewList.map((indicator) => {
            return indicator.objectives = [...indicator.objectives, { name: "", connection: false }]
        });
        setIndicators([...indicatorsNewList]);
    }
    const actionsUpdate = () => {
        setActions([...actions, { name: "", connection: false }]);
        const goalsNewList = Array.from(goals);
        goalsNewList.map((goal) => {
            return goal.actions = [...goal.actions, { name: "", connection: false }]
        });
        setGoals([...goalsNewList]);
        const indicatorsNewList = Array.from(indicators);
        indicatorsNewList.map((indicator) => {
            return indicator.actions = [...indicator.actions, { name: "", connection: false }]
        });
        setIndicators([...indicatorsNewList]);
    }
    return (
        <Fragment>
            <div>WorkPlan</div>
            <p>{t("text1")}</p>
            <p>{t("text2")}</p>
            <p>{t("text3")}</p>
            <p>{t("text4")}</p>
            <p>{t("text5")}</p>
            <p>{t("text6")}</p>
            <p>{t("text7")}</p>
            <p>{t("text8")}</p>
            <p>{t("text9")}</p>
            <p>{t("text10")}</p>
            <p>{t("text11")}</p>
            <p>{t("text12")}</p>
            <p>{t("text13")}</p>
            <p>{t("text14")}</p>
            <p>{t("text15")}</p>
            <p>{t("text16")}</p>
            <div className='create_a_table'>
                <div>

                <div className='table_options'>
                    <p>
                        Goals
                    </p>
                    {goals.map((goal, i) => (
                        <GoalsSelect state={goals} setState={setGoals} id={i} />
                    ))}
                    <button type='button' onClick={() => setGoals([...goals, { name: "", objectives: [...objectives], actions: [...actions] }])}>+</button>
                </div>
                    </div>
                    <div>

                <div className='table_options'>
                    <p>
                        objectives
                    </p>
                    {objectives.map((goal, i) => (
                        <ObjectivSelect state={objectives} setState={setobjectives} id={i} updateGoals={setGoals} currentGoals={goals} />
                    ))}
                    <button type='button' onClick={objectivesUpdate}>+</button>
                    </div>
                </div>
                <div>

                <div className='table_options'>
                    <p>
                        Actions
                    </p>
                    {actions.map((goal, i) => (
                        <ActionSelect state={actions} setState={setActions} id={i} updateGoals={setGoals} currentGoals={goals} />
                    ))}

                    <button type='button' onClick={actionsUpdate}>+</button>
                </div>
                    </div>
                <div>

                <div className='table_options'>
                    <p>
                        Indicators
                    </p>
                    {indicators.map((goal, i) => (
                        <IndicatorsInput state={indicators} setState={setIndicators} id={i}/>
                    ))}

                    <button type='button' onClick={addIndicators}>+</button>
                </div>
                    </div>
            </div>
            <br />
            <br />
            <br />
            <div className='cash_flow_table'>
                <div className='table_section'>


                    <div>


                        {indicators.map((indicator, i) => (
                            <div key={i} className='small_target'> {/* Add a key prop for the outer div */}
                                {indicator.actions.map((it, j) => (

                                    <Pointer info={it} state={indicators} list={"actions"} point1={i} point2={j} updateState={setIndicators} />
                                ))}
                                <div className='mid_box_bottom'>{indicator.name}</div>
                                {indicator.objectives.map((it, j) => (
                                            // <div key={j} className='pointer'>{it.name}</div>
                                    <Pointer info={it} state={indicators} list={"objectives"} point1={i} point2={j} updateState={setIndicators} />
                                ))}
                            </div>
                        ))}
                    </div>
                </div>


                <div className='table_section'>
                    <div className='target_list'>
                        {actions.map((action, i) => (
                            <div className='first_box_mid'>{action.name}</div>
                        ))}
                    </div>
                    <div className='mid_box_mid'>
                        <p className='pra1'>Indicators</p>
                        <p className='pra2'>Objectives</p>
                        <p className='pra3'>Actions</p>
                        <p className='pra4'>Goals</p>
                    </div>
                    <div className='target_list'>
                        {objectives.map((objective, i) => (
                            <div className='first_box_mid'>{objective.name}</div>
                        ))}
                    </div>

                </div>
                <div className='table_section'>
                    <div>
                        {goals.map((goal, i) => (
                            <div key={i} className='small_target'> {/* Add a key prop for the outer div */}
                                {goal.actions.map((it, j) => (
                                    <Pointer info={it} state={goals} list={"actions"} point1={i} point2={j} updateState={setGoals} />
                                ))}
                                <div className='mid_box_bottom'>{goal.name}</div>
                                {goal.objectives.map((it, j) => (
                                    <Pointer info={it} state={goals} list={"objectives"} point1={i} point2={j} updateState={setGoals} />
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default WorkPlan;
import React, { Fragment, useState } from "react";
import "./AvatarModal.css";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import Video from "./talking_video.mp4";
import { useDispatch } from 'react-redux';
// import TextToSpeech from "../../TextToSpeech/TextToSpeech";

function AvatarModal() {
    const { i18n } = useTranslation();
    const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
    const [avatar, setAvatar] = useState(true);
    const [userInput, setUserInput] = useState("");
    const [aiResponse, setAiResponse] = useState("");
    const dispatch = useDispatch();

    const muteFunc = (e) => {
        e.preventDefault();
        dispatch({ type: "mute", payload: 0.0 });
    };

    const sendPrompt = async (e) => {
        try {
            e.preventDefault();
            console.log("sendPrompt");
            console.log(`BACKEND_BASE_URL:${BACKEND_BASE_URL}`);
            const response = await fetch(`${BACKEND_BASE_URL}/openai`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt: userInput, userToken: '' }), // todo: pass userToken here
            });

            const data = await response.json();
            const lastResponse = data.lastResponse;
            console.log(lastResponse)
            setAiResponse(lastResponse);
        } catch (error) {
            console.error('Error fetching AI response:', error);
        }
    };

    return (
        <div className={`player-wrapper-ai ${i18n.language === "he" ? "rtl" : "ltr"}`}>
            {avatar && (
                <div className="video-container-ai">
                    <div className="container">
                        <button className="exsit-button" onClick={() => setAvatar(false)}>X</button>
                        <button className="mute-button" onClick={(e) => muteFunc(e)}>&#x1F507;</button>
                    </div>
                    <ReactPlayer
                        className="react-player"
                        url={Video}
                        width={"300px"}
                        height={"200px"}
                        playing={true}
                    />
                    <div className="container_row">
                        <input
                            className="input_ai"
                            placeholder="Ask AI"
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                        />
                        <button onClick={(e) => sendPrompt(e)} className="button_ai">&#x1F5E3;</button>
                        {aiResponse && (
                            <Fragment>
                                <div className="ai_response">{aiResponse}</div>
                                {/* <TextToSpeech text={aiResponse} /> */}
                            </Fragment>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default AvatarModal;

import React, { Fragment } from 'react'
import { useTranslation } from "react-i18next";
import "./ActionSelect.css"
function ActionSelect(props) {
    const { t} = useTranslation(["Action"]);
    const {state, setState, id, updateGoals, currentGoals} = props;
    const addAction = (e)=> {
      setState([...state.slice(0, id), {...state[id],name:e.target.value},...state.slice(1 + id)]);
      const newList = Array.from(currentGoals);
      newList.map((goal, i)=>{
        return goal.actions = [...goal.actions.slice(0,id),{...goal.actions[id], name:e.target.value},...goal.actions.slice([1 +id]) ]
      });
      updateGoals([...newList]);
    }
    return (
        <Fragment>
    <input  type="text" list="action" placeholder={t('option_1')} className='action_work_plan' contentEditable onBlur={addAction}></input>
    <datalist id="action">
      <option>{t('option_1')}</option>
      <option>{t('option_2')}</option>
      <option>{t('option_3')}</option>
      <option>{t('option_4')}</option>
      <option>{t('option_5')}</option>
      <option>{t('option_6')}</option>
      <option>{t('option_7')}</option>
      <option>{t('option_8')}</option>
      <option>{t('option_9')}</option>
      <option>{t('option_10')}</option>
      <option>{t('option_11')}</option>
      <option>{t('option_12')}</option>
      <option>{t('option_13')}</option>
      <option>{t('option_14')}</option>
      <option>{t('option_15')}</option>
      <option>{t('option_16')}</option>
      <option>{t('option_17')}</option>
      <option>{t('option_18')}</option>
      <option>{t('option_19')}</option>
      <option>{t('option_20')}</option>
      <option>{t('option_21')}</option>
      <option>{t('option_22')}</option>
      <option>{t('option_23')}</option>
      <option>{t('option_24')}</option>
      <option>{t('option_25')}</option>
      <option>{t('option_26')}</option>
      <option>{t('option_27')}</option>
      <option>{t('option_28')}</option>
      <option>{t('option_29')}</option>
      <option>{t('option_30')}</option>
      <option>{t('option_31')}</option>
      <option>{t('option_32')}</option>
      <option>{t('option_33')}</option>
      <option>{t('option_34')}</option>
      <option>{t('option_35')}</option>
      <option>{t('option_36')}</option>
      <option>{t('option_37')}</option>
      <option>{t('option_38')}</option>


    </datalist>
            </Fragment>
  )
}

export default ActionSelect;
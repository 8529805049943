import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import AvatarModal from '../Modals/AvatarModal/AvatarModal';
import { useSelector } from 'react-redux';

function TextToSpeech(props) {

  const { text } = props;
  const volume = useSelector(state => state.app.volume);

  const { i18n } = useTranslation("App"); // ensure the namespace is correct
  const language = i18n.language;
  var msg = new SpeechSynthesisUtterance();

  useEffect(() => {
    var voices = window.speechSynthesis.getVoices();
    msg.voiceURI = "native";
    msg.volume = volume;
    msg.rate = 1;
    msg.pitch = 0.8;
    msg.text = text;
    // msg.lang = 'en-US';
    if (language === "he") {
      msg.lang = 'he-IL';
      msg.voice = voices[3];
    }
    else {
      msg.lang = 'en-US';
      msg.voice = voices[1];
    }
  }, [language, volume,text]);

  speechSynthesis.speak(msg);


  return (
    <AvatarModal />
  )
}

export default TextToSpeech;




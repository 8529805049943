import "./ExcellButton.css"
// import {handleExport, handleExportFistPage} from "../../../Excel/generateBudgetData"
function ExcelButton(props) {
  const {name , data, excel} =props;
  const excelfunc=(e)=>{
    e.preventDefault();
    excel(data, name);
  }
  return (
    <div className='container_align_end'>
      <button type='button' className="xl-button" onClick={(e)=> {excelfunc(e)}}/>
     </div>
  )
}

export default ExcelButton

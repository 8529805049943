import React, { Fragment, useState } from "react";
import "./AdvertisingChannels.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSelector } from "react-redux";

function AdvertisingChannels() {
  const { t, i18n } = useTranslation(["Sales"]);
  const currency = useSelector((state) => state.income.currency);
  const [choice, setChoice] = useState(0);
  return (
    <div className={`content ${i18n.language === "he" ? "right" : "left"}`}>
      <div className="main_container">
        <h1 className="title_text">
          {t("Stage_3_Adapting_advertising_channels")}
        </h1>
        <p className="body_text">
          {t("There_are_several_different_advertising_channels")}
        </p>
        <p className="body_text">
          {t("High_efficiency_leads_to_budget_savings")}
        </p>
        <div className="select_container">
          <h3 className="select_title">
            {t("Include_the_advertising_budget")}
          </h3>
          <div className="select_options">
            <select
              className="selset"
              onChange={(e) => setChoice(Number(e.target.value))}
            >
              <option selected disabled hidden></option>
              <option value={1}>{`${t("Up_to_10000")}`}</option>
              <option value={2}>{`${t("Up_to_20000")}`}</option>
              <option value={3}>{`${t("Over_20000")}`}</option>
            </select>
            <FontAwesomeIcon icon={currency} />
          </div>
        </div>
        <div className="continu_text_body">
          {choice === 1 && (
            <>
              <p>{`${t("text_up_to_10000_1")}`}</p>
              <p>{`${t("text_up_to_10000_2")}`}</p>
            </>
          )}
          {choice === 2 && (
            <Fragment>
              <p>{`${t("text_up_to_20000_1")}`}</p>
              <p>{`${t("text_up_to_20000_2")}`}</p>
            </Fragment>
          )}
          {choice === 3 && (
            <Fragment>
              <p>{`${t("text_over_20000_1")}`}</p>
              <p>{`${t("text_over_20000_2")}`}</p>
              <p>{`${t("text_over_20000_3")}`}</p>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}
export default AdvertisingChannels;

import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import "./MonthTableRow.css";
import { months } from "../../../helpers/helpers";
import { formatNumber } from "../../../helpers/helpers";
function MonthTableRow({ id, text }) {
  const thisMonth = useSelector((state) => state.finance);
  const totalfMonth = months.reduce((base, mon) => {
    return (base += +thisMonth[mon][id]);
  }, 0);

  return (
    <Fragment>
      <input className="input-table-f " readOnly value={text} />
      {months.map((mon, i) => (
        <input
          key={i}
          className="input-table-f"
          defaultValue={formatNumber(thisMonth[mon][id])}
          disabled
        />
      ))}

      <input
        className="input-table-f"
        defaultValue={formatNumber(totalfMonth / 12)}
        disabled
      />
      <input
        className="input-table-f"
        defaultValue={formatNumber(totalfMonth)}
        disabled
      />
    </Fragment>
  );
}

export default MonthTableRow;

// import React, { Fragment, useEffect } from 'react'
import React, { Fragment } from 'react'
import { useTranslation } from "react-i18next";
import "./ObjectivSelect.css"
function ObjectivSelect(props) {
  const {state, setState, id, updateGoals, currentGoals} = props;
    const { t} = useTranslation(["Targets"]);
    const addTarget = (e)=> {
      setState([...state.slice(0, id), {...state[id],name:e.target.value},...state.slice(1 + id)]);
      const newList = Array.from(currentGoals);
      newList.map((goal, i)=>{
        return goal.objectives = [...goal.objectives.slice(0,id),{...goal.objectives[id], name:e.target.value},...goal.objectives.slice([1 +id]) ]
      })
      updateGoals([...newList]);
    }
  return (
    <Fragment>
<input  type="text" list="targets"  className='targets' placeholder={t('monthly_increase')} contentEditable onBlur={addTarget}></input>
<datalist id="targets">
  <option>{t('monthly_increase')}</option>
  <option>{t('happiness_index')}</option>
  <option>{t('monthly_production')}</option>
  <option>{t('minimum_profit')}</option>
  <option>{t('purchase_property')}</option>
  <option>{t('improving_operating_profi')}</option>
  <option>{t('generating_leads')}</option>
  <option>{t('minimum_transactions')}</option>
  <option>{t('unique_product')}</option>
  <option>{t('improving_profit_before_tax')}</option>
  <option>{t('weekly_control')}</option>
  <option>{t('increasing_financial_repayments')}</option>
  <option>{t('new_regional_distribution')}</option>
  <option>{t('franchise_store')}</option>
  <option>{t('minimum_customer_score')}</option>
  <option>{t('leaving_work')}</option>
  <option>{t('new_market')}</option>
  <option>{t('repetitive_jobs')}</option>

</datalist>
        </Fragment>
  )
}

export default ObjectivSelect;
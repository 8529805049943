import React, { Fragment } from 'react'
import { salesMonths } from '../../helpers/helpers'
import './MarketingControl.css'
import MarketingTableRow from '../../components/Tables/MarketingTable/MarketingTableRow'
import { useTranslation } from "react-i18next";

function MarketingControl() {
    const { t } = useTranslation("MarketingControl");

    const titels = [
        t("Total budget"),
        t("Retainer for suppliers"),
        t("Cost of marketing staff"),
        t("Total Marketing"),
        t("Amount of leads"),
        t("Relevant leads"),
        t("Total of close deals"),
        t("Income from leads"),
        t("Effectiveness for leads"),
        t("Effectiveness for salse"),
        t("Sales efficiency")
    ];

    return (
        <Fragment>
            <div className="box_shadow container_grid_12 margin_sales_effectivness">
                <p className='p'>{t("month")}</p>
                {titels.map((titel, i) => (
                    <p className='p' key={i}>{titel}</p>
                ))}
                {
                    salesMonths.map((mon, i) => (
                        <MarketingTableRow month={mon} key={i} />
                    ))
                }
            </div>
            <ol>
            <li>{t("Marketing effectiveness for leads explanation")}</li>
            <li>{t("Marketing effectiveness for sales explanation")}</li>
            <li>{t("Sales efficiency explanation")}</li>
            </ol>
        </Fragment>
    )
}

export default MarketingControl

import VideoNewModal from "../../components/Modals/VideoNewModal/VideoNewModal";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import './CustomerExperience.css';

function CustomerExperience() {
  const { t, i18n } = useTranslation("Experience");
  const [image, setImage] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const [selectedTime, setSelectedTime] = useState("3"); // Default is 3 days
  const [customerName, setCustomerName] = useState("");
  const [surveyorName, setSurveyorName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const isHebrew = i18n.language === "he";

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Set the uploaded image to display it
      };
      reader.readAsDataURL(file);
    }
  };

  const handleExcelUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setExcelFile(file);
    }
  };
  return (
    <Fragment>
     <br></br>  
      <h3>{t("title")}</h3>

      <div className='video-container_p'>
        <VideoNewModal videoUrl={t('video_url')} />
      </div>

      <div className='text'>
      <h3>{t("first_title")}</h3>
      <p>{t("text1")}</p>
      <p>{t("text")}</p>
      <p>{t("text3")}</p>
      <p>{t("text4")}</p>
      <p>{t("text5")}</p>
      <p>{t("text6")}</p>
      <p>{t("text7")}</p>
    </div>

      <div className='procedure_table'>
        <table>
          <tbody>
            {/* Top Section */}
            <tr>
              <td className="small-cell">
                <label>{t('document_number')}</label>
                <input type="text" className="input-field" />
              </td>
              <td className="small-cell">
                <label>{t('date')}</label>
                <input type="text" className="input-field" />
              </td>
              <td rowSpan="3" className="large-cell logo-cell">
                {/* Image Upload Section */}
                <label>{t('upload_logo')}</label>
                <input type="file" onChange={handleImageUpload} />
                {image && <img src={image} alt="Uploaded Logo" className="company-logo" />}
              </td>
            </tr>
            <tr>
              <td className="small-cell">
                <label>{t('update_number')}</label>
                <input type="text" className="input-field" />
              </td>
              <td className="large-cell" rowSpan="2">
                <label>{t('company_name')}</label>
                <input type="text" className="input-field" />
              </td>
            </tr>
            <tr>
              <td className="small-cell">
                <label>{t('update_date')}</label>
                <input type="text" className="input-field" />
              </td>
            </tr>
            <tr>
              <td className="small-cell">
                <label>{t('procedure_name')}</label>
                <input type="text" className="input-field" />
              </td>
              <td className="small-cell">
                <label>{t('page_number')}</label>
                <input type="text" className="input-field" />
              </td>
            </tr>

            {/* Content Section */}
            <tr>
              <td colSpan="3">

                <div className="content-section">
                  <p>{t('text_1')}</p>
                  <p>
        {t("text2_part1")}
        <select
          value={selectedTime}
          onChange={(e) => setSelectedTime(e.target.value)}
        >
          <option value="1">{t('1 day')}</option>
          <option value="3">{t('3 days')}</option>
          <option value="7">{t('1 week')}</option>
          <option value="14">{t('1 month')}</option>
        </select>
        {t("text2_part2")}
      </p>
                  <p>{t('text_3')}</p>
                  <p>{t('text_4')}</p>
                  <p>
                {isHebrew ? t("שלום") : t("Hello")} 
                <input
                  type="text"
                  className="inline-input"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  placeholder={isHebrew ? t('שם הלקוח') : t('(Customer Name)')}
                  size={customerName.length || 15}
                />, {isHebrew ? t("זה") : t("this is")} 
                <input
                  type="text"
                  className="inline-input"
                  value={surveyorName}
                  onChange={(e) => setSurveyorName(e.target.value)}
                  placeholder={isHebrew ? t('שם הסוקר') : t('(Surveyor Name)')}
                  size={surveyorName.length || 15}
                /> {isHebrew ? t("מ") : t("from")}
                <input
                  type="text"
                  className="inline-input"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder={ t('XXXX')}
                  size={companyName.length || 10}
                /> {isHebrew ? t(" ") : t("Company")}
                  </p>
                  <p>{t('text_6')}</p>
                  <p>{t('text_7')}</p>
                  <p>{t('text_8')}</p>
                  <p>{t('text_9')}</p>
                  <p>{t('text_10')}</p>
                  <p>{t('text_11')}</p>
                  <p>{t('text_12')}</p>
                  <p>{t('text_13')}</p>
                </div>
              </td>
            </tr>

            {/* Footer Section */}
            <tr>
              <td className="small-cell">
                <label>{t('prepared_by')}</label>
                <input type="text" className="input-field" />
              </td>
              <td className="small-cell">
                <label>{t('reviewed_by')}</label>
                <input type="text" className="input-field" />
              </td>
              <td className="small-cell">
                <label>{t('approved_by')}</label>
                <input type="text" className="input-field" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Excel Upload Section */}
      <div className="excel-upload-section">
        <h3>{t("upload_excel")}</h3>
        <input type="file" accept=".xlsx, .xls" onChange={handleExcelUpload} />
        {excelFile && <p>{excelFile.name} {t("file_uploaded")}</p>}

        {/* Explanation Section - English */}
        <div className="upload-instructions">
          <h4>{t("instructions_title")}</h4>
          <p>{t("excel_instructions")}</p>
        </div>


      </div>


    </Fragment>
  );
}

export default CustomerExperience;

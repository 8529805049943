import React, { useState } from "react";
import "./DefineRules.css";
import { useTranslation } from "react-i18next";

export default function DefineRules() {
  const { t } = useTranslation("DefineRules");

  const [rules, setRules] = useState([
    {
      documentNumber: "",
      publishDate: "",
      companyName: "",
      procedureName: "",
      content: "",
      actionNumber: "",
      approvalContent: "",
      signature: "",
      managerPosition: "",
    },
  ]);

  const addRule = () => {
    setRules([
      ...rules,
      {
        documentNumber: "",
        publishDate: "",
        companyName: "",
        procedureName: "",
        content: "",
        actionNumber: "",
        approvalContent: "",
        signature: "",
        managerPosition: "",
      },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const newRules = [...rules];
    newRules[index][field] = value;
    setRules(newRules);
  };

  return (
    <div className="define-rules-container">
      {rules.map((rule, index) => (
        <div key={index} className="rule-page">
          <table className="rule-table">
            <tbody>
              <tr>
                <td>{t("documentNumber")}:</td>
                <td>
                  <input
                    type="text"
                    value={rule.documentNumber}
                    onChange={(e) =>
                      handleInputChange(index, "documentNumber", e.target.value)
                    }
                  />
                </td>
                <td rowSpan="3" colSpan="2" className="company-logo">
                  {t("companyLogo")}
                </td>
              </tr>
              <tr>
                <td>{t("publishDate")}:</td>
                <td>
                  <input
                    type="date"
                    value={rule.publishDate}
                    onChange={(e) =>
                      handleInputChange(index, "publishDate", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>{t("companyName")}:</td>
                <td>
                  <input
                    type="text"
                    value={rule.companyName}
                    onChange={(e) =>
                      handleInputChange(index, "companyName", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>{t("procedureName")}:</td>
                <td>
                  <input
                    type="text"
                    value={rule.procedureName}
                    onChange={(e) =>
                      handleInputChange(index, "procedureName", e.target.value)
                    }
                  />
                </td>
                <td>{t("actionNumber")}:</td>
                <td>
                  <input
                    type="text"
                    value={rule.actionNumber}
                    onChange={(e) =>
                      handleInputChange(index, "actionNumber", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="4" className="content-area">
                  <textarea
                    placeholder={t("contentPlaceholder")}
                    value={rule.content}
                    onChange={(e) =>
                      handleInputChange(index, "content", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>{t("preparedBy")}:</td>
                <td>
                  <input
                    type="text"
                    value={rule.approvalContent}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "approvalContent",
                        e.target.value
                      )
                    }
                  />
                </td>
                <td>{t("signature")}:</td>
                <td>
                  <input
                    type="text"
                    value={rule.signature}
                    onChange={(e) =>
                      handleInputChange(index, "signature", e.target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>{t("responsiblePerson")}:</td>
                <td colSpan="3">
                  <input
                    type="text"
                    value={rule.managerPosition}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "managerPosition",
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
      <button className="add-rule-button" onClick={addRule}>
        &#x2b; {t("addNewRule")}
      </button>
    </div>
  );
}

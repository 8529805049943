import React, { Fragment } from 'react'
import './MonthSelect.css'
// import { months } from '../../helpers/helpers';
import { useDispatch,useSelector } from 'react-redux';

function FinancePlanTable (props) {
  const {text, setIndex, resetForm, months } = props;
  const dispatch = useDispatch();
  const divs = document.querySelectorAll('.month');
  const theMonth = useSelector(state => state.finance);



  return (
    <Fragment>
      <div className='container_grid_13 text_center '>
        {months.map((mon,i ) => (
          <Fragment key={i}>
          <p className='month' id={mon} onClick={(e) => {
            if(!(e.target.id === "Total" || e.target.id === "Average")){
            document.getElementById(resetForm).reset()
            divs.forEach(div => {
              div.classList.remove('picked');
            });
            dispatch({type:"month",payload:mon })
            setIndex(i)
            if(!(theMonth[mon].active)){
            dispatch({type:mon ,payload: {...theMonth.Default,month:mon}})
            }
            e.currentTarget.classList.add('picked')}
            }}>{text[i]}</p>
          </Fragment>
          ))}
      </div>
    </Fragment>
  )
}

export default FinancePlanTable

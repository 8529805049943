import React, {useEffect, useState } from "react";
import ReasonSelect from "../../Selectors/ReasonSelect/ReasonSelect";
import FivePositionSelect from "../../Selectors/FivePositionSelect/FivePositionSelect";
import { useDispatch, useSelector} from "react-redux";
import "./IncomeTableInput.css";
import { useTranslation } from "react-i18next";
import { formatNumber } from '../../../helpers/helpers';

function IncomeTableInput(props) {
    const {month, input_number, action} = props;
    const { t } = useTranslation("Incom");
    const [status , setStatus] = useState(0)
    const [selectedOption, setSelectedOption] = useState("1");
    // const current_status = useSelector(state => state.income.current_account_status);
    const current_month = useSelector(state => state.income[month]);
    const current_account_status_base = useSelector(state => state.income.current_account_status_base);
    const Month_action = useSelector(state => state.income.Month_action);
    const dispatch = useDispatch();
useEffect(()=>{
    if(Month_action.length > 0 && (Month_action[input_number].income_revenue > 0 ||Month_action[input_number].payments_expenses > 0 )){
        const current = Month_action.slice(0, 1 + input_number).reduce((acc, action)=>{
           return +acc + Number(action.income_revenue) - Number(action.payments_expenses)
        }, 0)
        if(current !== 0) {setStatus( +current_account_status_base + current)
    }
    else{
        setStatus(0)    }}
    else{
        setStatus(0)
    }
},[Month_action, current_account_status_base, input_number])
    const handleSelectChange = (e) => {
        const {name, value, id} = e.target
        // dispatch({ type: month + page, payload: {...current_month, [e.target.name]:e.target.value}});
        dispatch({ type: "month_action", payload:[...Month_action.slice(0, [+id]),{...Month_action[+id],[name]: value}, ...Month_action.slice([1+ +id])] });
        return
      };
      const addExpenseValue = (e) => {
          const { value, id} = e.target
          const totalI = +Month_action.slice(0,[+id]).reduce((acc, action)=> {return acc + +action.income_revenue },0) + +Month_action.slice([1+ +id]).reduce((acc, action)=> {return +acc + +action.income_revenue },0)
          const totalE = +Month_action.slice(0,[+id]).reduce((acc, action)=> {return +acc + +action.payments_expenses },value) + +Month_action.slice([1+ +id]).reduce((acc, action)=> {return +acc + +action.payments_expenses },0)
          dispatch({ type: "total_income", payload: totalI });
        dispatch({ type: "total_expense", payload: totalE });
        // dispatch({ type: "payments_expenses", payload: totalE });
        // dispatch({ type: "current_account_status", payload: +current_status + -value });
        dispatch({ type: "month_action", payload:[...Month_action.slice(0, [+id]),{...Month_action[+id],payments_expenses: value,income_revenue: 0}, ...Month_action.slice([1+ +id])] });
        return;
      };
      const addIncomeValue = (e) => {
          const { value, id} = e.target
        //   const current = (value > 0)? Month_action.slice(0, input_number).reduce((acc, action)=>{
        //     return +acc + Number(action.income_revenue) - Number(action.payments_expenses)
        //  }, current_account_status_base)
        // : 0;
        // current_account_status: +current + +value
        // setStatus(+current_status + +value)
        const totalI = +Month_action.slice(0,[+id]).reduce((acc, action)=> {return +acc + +action.income_revenue },value) + +Month_action.slice([1+ +id]).reduce((acc, action)=> {return +acc + +action.income_revenue },0)
        const totalE = +Month_action.slice(0,[+id]).reduce((acc, action)=> {return acc + +action.payments_expenses },0) + +Month_action.slice([1+ +id]).reduce((acc, action)=> {return +acc + +action.payments_expenses },0)
        dispatch({ type: "total_income", payload: totalI });
        dispatch({ type: "total_expense", payload: totalE });
        // dispatch({ type: "payments_expenses", payload: totalE });
        dispatch({ type: "month_action", payload:[...Month_action.slice(0, [+id]),{...Month_action[+id],payments_expenses: 0,income_revenue: value}, ...Month_action.slice([1+ +id])] });

        return
     };




      const addData = (e) => {
        const { value, name, id} = e.target
        dispatch({ type: "month_action", payload:[...Month_action.slice(0, [+id]),{...Month_action[+id],[name]: value}, ...Month_action.slice([1+ +id])] });
        return
      };

  return (
    <div id="status" className="container_grid_nine">
    <div>
      <input
     className="date"
     id={input_number}
     name="date"
     type="date"
     onChange={addData}
     defaultValue={action.date !== "0000-00-00"? action.date : ""}
       />
    </div>
    <div>
      <select  id={input_number} value={action.income_expence_select} name={"income_expence_select"} className="income-expence-select" onChange={(e)=>{
        handleSelectChange(e)
        setSelectedOption(e.target.value)}}>
        <option value="1">{t("Income")}</option>
        <option value="2">{t("Expence")}</option>
      </select>
    </div>
    <div>
      <ReasonSelect month={current_month} id={input_number} aValue={action.reason_select} handleSelectChange={handleSelectChange}/>
    </div>
    <div>
      <input className="detail_name" type="text"  name={"detail_name"}defaultValue={action.detail_name} onChange={addData}/>
    </div>
    <div>
      <FivePositionSelect month={current_month} fpValue={action.fivePositionSelect} id={input_number} handleSelectChange={handleSelectChange} />
    </div>
    <div>
      <input
        name={'Expense'}
        defaultValue={action.payments_expenses}
        className="expense"
        placeholder="0"
        id={input_number}
        dir="rtl"
        onBlur={(e)=> addExpenseValue(e)}
        disabled={selectedOption === "1"}
        type="number"
      />
    </div>
    <div>
      <input
        name={"Income"}
        className="income"
        placeholder="0"
        defaultValue={action.income_revenue}
        dir="rtl"
        onBlur={(e)=> addIncomeValue(e)}
        id={input_number}
        // onChange={(e)=>addIncomeValue(e)}
        disabled={selectedOption === "2"}
        type="number"
      />{" "}
    </div>
    <div>
      <input
      className="current-status-table"
        name="current_account_status"
        placeholder="0"
        readOnly
        value={formatNumber(status)}
        dir="rtl"
        disabled
      />{" "}
    </div>
    <div>
      <input className="notes"   id={input_number} type="text" defaultValue={current_month? current_month["notes"+input_number] : ""} name={"notes" + input_number} onChange={addData}/>
    </div>
  </div>
  )
}

export default IncomeTableInput;